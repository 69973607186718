import * as React from 'react'

import { useJobSearch } from './JobSearchProvider'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useTranslation } from '../../../hooks/useTranslation'

export default function JobBoardSampler() {
  const { jobBoards, urlSearchParams } = useJobSearch()
  const { t } = useTranslation('JobBoardSampler')
  const isDesktop = useIsDesktop()

  if (jobBoards.length < 3) {
    return null
  }

  const boards = isDesktop ? jobBoards.slice(0, 3) : jobBoards.slice(0, 2)

  return (
    <div className="flex flex-col gap-3 rounded-2xl border-2 border-alto-300 p-4 md:gap-4 md:p-6">
      <h2 className="text-sm font-bold md:text-lg">
        {t('title.recommended_job_boards')}
      </h2>

      <div className="flex flex-col justify-between gap-3 md:flex-row md:gap-4">
        {boards.map((jobBoard) => (
          <a
            href={`/job-search/boards/${jobBoard.id}`}
            target="_blank"
            rel="noopener noreferrer"
            key={jobBoard.id}
            className="basis-1/3 rounded-lg  text-black ring-offset-background transition-colors hover:bg-white hover:text-black  hover:ring-2 hover:ring-intoo-blue-medium  hover:ring-ring hover:ring-offset-4 hover:ring-offset-background focus-visible:bg-white focus-visible:text-black focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-intoo-blue-medium focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background"
          >
            <div className="flex gap-1 md:flex-row">
              <img
                src={jobBoard.logo_url}
                alt={jobBoard.title}
                className="h-12 w-12 rounded-lg object-cover"
              />
              <div className="flex-grow flex-col justify-end overflow-hidden pl-4">
                <h3 className="text-sm font-bold">{jobBoard.title}</h3>
                <div className="line-clamp-2 text-xs">
                  {jobBoard.long_description}
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>

      <a
        href={`/job-search/boards?query=${urlSearchParams.get('what')}`}
        className="text-xs text-[#1978CB] underline hover:no-underline"
      >
        {t('link.view_all_job_boards')}
      </a>
    </div>
  )
}
