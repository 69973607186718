import * as React from 'react'
export const useFilters = ({ form, setUrlSearchParams }) => {
  const { watch } = form

  const what = watch('what')
  const where = watch('where')
  const filter_experience_level = watch('filter_experience_level')
  const filter_job_type = watch('filter_job_type')
  const filter_employment_type = watch('filter_employment_type')
  const filter_date_posted = watch('filter_date_posted')

  React.useEffect(() => {
    setUrlSearchParams(
      (prev) => {
        if (what) prev.set('what', what)
        else prev.delete('what')

        if (where) prev.set('where', where)
        else prev.delete('where')

        return prev
      },
      { replace: true },
    )
  }, [what, where, setUrlSearchParams])

  React.useEffect(() => {
    setUrlSearchParams(
      (prev) =>
        addArrayToUrlSearchParams(
          prev,
          'filter_experience_level',
          filter_experience_level,
        ),
      { replace: true },
    )
  }, [filter_experience_level, setUrlSearchParams])

  React.useEffect(() => {
    setUrlSearchParams(
      (prev) =>
        addArrayToUrlSearchParams(prev, 'filter_job_type', filter_job_type),
      { replace: true },
    )
  }, [filter_job_type, setUrlSearchParams])

  React.useEffect(() => {
    setUrlSearchParams(
      (prev) =>
        addArrayToUrlSearchParams(
          prev,
          'filter_employment_type',
          filter_employment_type,
        ),
      { replace: true },
    )
  }, [filter_employment_type, setUrlSearchParams])

  React.useEffect(() => {
    setUrlSearchParams(
      (prev) =>
        addArrayToUrlSearchParams(
          prev,
          'filter_date_posted',
          filter_date_posted,
        ),
      { replace: true },
    )
  }, [filter_date_posted, setUrlSearchParams])

  const hasFilters =
    filter_experience_level.length ||
    filter_job_type.length ||
    filter_employment_type.length ||
    filter_date_posted.length

  const resetFilters = () => {
    form.setValue('filter_experience_level', [])
    form.setValue('filter_job_type', [])
    form.setValue('filter_employment_type', [])
    form.setValue('filter_date_posted', [])
  }

  return {
    hasFilters,
    resetFilters,
  }
}

// TODO: Move this to a shared location
export const addArrayToUrlSearchParams = (urlSearchParams, key, array) => {
  urlSearchParams.delete(`${key}[]`)
  array.forEach((value) => {
    urlSearchParams.append(`${key}[]`, value)
  })
  urlSearchParams.sort()

  return urlSearchParams
}
