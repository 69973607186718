import React, { useRef, useEffect } from 'react'
import { cn } from '../../lib/utils'
import { getIcon } from './icons'
import { useNav } from './NavProvider'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'

const NavLink = ({ item, theme }) => {
  const { activeNav } = useNav()
  const { slug } = item
  const extraClasses = theme == 'green' ? 'bg-intoo-green-coachcta' : ''
  if (slug === activeNav) {
    return <NavLinkActive item={item} className={extraClasses} />
  } else {
    return <NavLinkInactive item={item} />
  }
}
export default NavLink

const defaultInactiveClasses =
  'text-normal flex cursor-pointer items-center px-3 py-2.5 font-medium leading-5 tracking-tight text-cod-gray-800'
const defaultActiveClasses = cn(
  defaultInactiveClasses,
  'rounded-lg bg-intoo-blue-medium text-white',
)

const NavLinkInactive = ({ item, className }) => {
  const {
    setShowPopup,
    setActiveNav,
    getLink,
    setActiveSubNav,
    navigateTo,
    isExpanded,
  } = useNav()
  const { title, children, slug } = item
  const Icon = getIcon(slug)
  const inactiveClasses = cn(defaultInactiveClasses, className)
  const path = getLink(item)
  const activate = () => {
    setActiveNav(slug)
    setActiveSubNav(null)
    if (path) {
      navigateTo(path)
    }
    if (!isExpanded && children) {
      setShowPopup(true)
    }
  }
  if (isExpanded) {
    return (
      <Link className={inactiveClasses} onClick={activate}>
        {Icon && <Icon className="mr-2 inline-block h-6 w-6" />}
        {title}
        {children && (
          <ChevronDownIcon className="ml-auto inline-block h-4 w-4 stroke-2" />
        )}
      </Link>
    )
  } else {
    return (
      <Link className={inactiveClasses} onClick={activate} title={title}>
        {Icon && <Icon className="inline-block h-6 w-6" />}
      </Link>
    )
  }
}

const NavLinkActive = ({ item, className }) => {
  const { setActiveNav, setActiveSubNav, isExpanded } = useNav()
  const { title, children, slug } = item
  const Icon = getIcon(slug)
  const activeClasses = cn(defaultActiveClasses, className)
  const deactivate = () => {
    if (children) {
      setActiveNav(null)
      setActiveSubNav(null)
    }
  }
  if (isExpanded) {
    return (
      <>
        <Link className={activeClasses} onClick={deactivate}>
          {Icon && <Icon className="mr-2 inline-block h-6 w-6" />}
          {title}
          {children && (
            <ChevronUpIcon className="ml-auto inline-block h-4 w-4 stroke-2" />
          )}
        </Link>
        <SubNav items={children} />
      </>
    )
  } else {
    return (
      <>
        <PopupSubNav items={children} />
        <Link className={activeClasses} onClick={deactivate} title={title}>
          {Icon && <Icon className="inline-block h-6 w-6" />}
        </Link>
      </>
    )
  }
}

const PopupSubNav = ({ items }) => {
  const popupRef = useRef(null)
  const { showPopup, setShowPopup } = useNav()
  useEffect(() => {
    const handleClick = (e) => {
      if (!popupRef.current.contains(e.target)) {
        setShowPopup(false)
      }
    }
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [popupRef, setShowPopup])
  if (!items) {
    return null
  }
  return (
    <div ref={popupRef} className={showPopup ? 'visible' : 'hidden'}>
      <div className="fixed left-24 flex flex-col gap-2 rounded-xl border border-solid border-[#0000001a] bg-white p-2 shadow-xl">
        {items.map((child, childIndex) => (
          <SubNavLink key={childIndex} item={child} />
        ))}
      </div>
    </div>
  )
}

const SubNav = ({ className, items }) => {
  const subNavClasses = cn('flex flex-col gap-2', className)
  if (!items) {
    return null
  }
  return (
    <div className={subNavClasses}>
      {items.map((child, childIndex) => (
        <SubNavLink key={childIndex} item={child} withDecoration />
      ))}
    </div>
  )
}

const Link = ({ onClick, title, className, children }) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onClick()
    }
  }
  return (
    <a
      className={className}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      title={title}
      role="link"
      tabIndex="0"
    >
      {children}
    </a>
  )
}

const SubNavLink = ({ withDecoration, item }) => {
  const { getLink, activeSubNav, setShowPopup, setActiveSubNav, navigateTo } =
    useNav()
  const { title } = item
  const inactiveClasses = 'px-3 py-2 tracking-tight text-sm w-full'
  const activeClasses = cn(inactiveClasses, 'bg-[#BFDCF5] rounded font-medium')
  const active = title === activeSubNav
  const path = getLink(item)
  const activate = () => {
    setActiveSubNav(title)
    setShowPopup(false)
    if (path) {
      navigateTo(path)
    }
  }
  return (
    <Link
      className="flex cursor-pointer flex-row items-center hover:rounded hover:bg-cod-gray-100"
      onClick={activate}
    >
      {withDecoration && <div className="w-10 pr-2 text-right">&bull;</div>}
      <div className={active ? activeClasses : inactiveClasses}>{title}</div>
    </Link>
  )
}
