import React from 'react'
import { cn } from '../../lib/utils'
import { ScrollArea } from '../../lib/ui/scroll-area'
import { NavProvider, useNav } from './NavProvider'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import NavGroup from './NavGroup'

const LeftNav = ({ menuItems, ...props }) => {
  return (
    <NavProvider {...props}>
      <VerticalNav>
        <ScrollArea className="h-screen px-6">
          <div className="my-6">
            <UsernameHeader>User Name</UsernameHeader>
            <GoalHeader>Landing a Similar Job</GoalHeader>
            {menuItems.map((section, sectionIndex) => (
              <NavGroup key={sectionIndex} section={section}></NavGroup>
            ))}
          </div>
        </ScrollArea>
      </VerticalNav>
    </NavProvider>
  )
}
export default LeftNav

const GoalHeader = ({ className = null, children }) => {
  const { isExpanded } = useNav()
  const headerClasses = cn(
    'h-9 w-full items-center text-sm font-medium uppercase leading-5 tracking-wide text-intoo-blue-medium',
    className,
  )
  if (isExpanded) {
    return (
      <div className={headerClasses}>
        Goal: <span className="underline">{children}</span>
      </div>
    )
  }
}

const UsernameHeader = ({ children }) => {
  const { isExpanded } = useNav()
  return (
    <div className="mb-6 flex h-11 w-full flex-row items-center">
      <div className="h-11 w-11 rounded-full bg-cod-gray-200"></div>
      {isExpanded && (
        <div className="ml-3 h-5 flex-grow text-sm font-medium">{children}</div>
      )}
    </div>
  )
}

const ToggleExpanded = () => {
  const { toggleExpanded, isExpanded } = useNav()
  const title = isExpanded ? 'Collapse menu' : 'Expand menu'
  const defaultToggleClasses =
    'absolute -right-4 top-8 rounded border border-solid border-[#F6F6F6] bg-white p-1.5 cursor-pointer'
  const toggleClasses = isExpanded
    ? defaultToggleClasses
    : cn(defaultToggleClasses, 'top-6')

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      toggleExpanded()
    }
  }
  return (
    <a
      onClick={toggleExpanded}
      onKeyDown={handleKeyDown}
      className={toggleClasses}
      aria-checked={isExpanded}
      role="switch"
      title={title}
      tabIndex="0"
    >
      {isExpanded ? (
        <ChevronLeftIcon className="h-r w-4" />
      ) : (
        <ChevronRightIcon className="h-r w-4" />
      )}
    </a>
  )
}

const VerticalNav = ({ children }) => {
  const { isExpanded } = useNav()
  const defaultNavClasses =
    'w-64 border-r border-[#0000001a] pb-6 font-roboto subpixel-antialiased relative'
  const collapsedClasses = cn(defaultNavClasses, 'w-22')
  const navClasses = isExpanded ? defaultNavClasses : collapsedClasses
  return (
    <nav className={navClasses}>
      <ToggleExpanded />
      {children}
    </nav>
  )
}
