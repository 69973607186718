import React, { useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import Selected from './SelectedComponent'
import Deselected from './DeselectedComponent'

const TrackTile = ({ tileInfo, expand, setExpand, expandIndex }) => {
  const { t } = useTranslation('sections')
  const [selected, setSelected] = useState(0)
  const sectionSlug = Object.keys(tileInfo)
  const section = t(`${sectionSlug}.name`)

  return (
    <>
      <div
        style={{ justifyContent: 'space-between', padding: '2rem' }}
        className={`display-flex track-title ${
          section === 'Onboarding' ? 'onboarding-tile' : ''
        }`}
      >
        <div className="marker-container">
          <i className="el-icon-map-marker"></i>
          <h3 style={{ marginTop: '0px', display: 'inline' }}>{section}</h3>
        </div>
        <div
          className="btn btn-primary"
          style={{
            fontSize: '12px',
            cursor: 'pointer',
            display: expand === expandIndex ? 'none' : 'block',
          }}
          onClick={() => setExpand(expandIndex)}
          title={'View ' + section}
        >
          <i className="el-icon-chevron-down"></i>
        </div>
      </div>
      <div
        className="display-flex"
        style={{ flexDirection: 'column', gap: '0rem 2rem' }}
      >
        {Object.values(tileInfo)[0].subsections.map((tile, idx) => {
          const tileSlug = Object.keys(tile)
          const tileInfo = tile[tileSlug]
          if (selected === idx)
            return (
              <div className="selected-tile" key={`expanded-${idx}`}>
                {' '}
                <Selected
                  header={t(`${sectionSlug}.subsections.${tileSlug}.name`)}
                  subtext={t(
                    `${sectionSlug}.subsections.${tileSlug}.description`,
                  )}
                  type={t(`${sectionSlug}.subsections.${tileSlug}.icon`)}
                  length={t(`${sectionSlug}.subsections.${tileSlug}.length`)}
                  url={tileInfo.url}
                />
              </div>
            )
          else
            return (
              <div
                className="unselected-tile"
                onClick={() => setSelected(idx)}
                style={{
                  padding: '2.25rem',
                  alignItems: 'center',
                  cursor: 'pointer',
                  borderBottom: '2px solid #e5e5e5',
                }}
                key={`expanded-${idx}`}
              >
                <Deselected
                  header={t(`${sectionSlug}.subsections.${tileSlug}.name`)}
                  type={t(`${sectionSlug}.subsections.${tileSlug}.icon`)}
                  length={t(`${sectionSlug}.subsections.${tileSlug}.length`)}
                />
              </div>
            )
        })}
      </div>
    </>
  )
}
export default TrackTile
