import React from 'react'
import DataProvider, { useData } from '../DataProvider'
import useTranslation, {
  TranslationProvider,
} from '../../shared/TranslationProvider'
import translations from '../locales.json'
import InterestSelect from '../InterestSelect'

const MyAccountSettingsInternal = () => {
  const {
    interest,
    desiredJobTitle,
    setDesiredJobTitle,
    desiredIndustry,
    setDesiredIndustry,
    aboutYourself,
    setAboutYourself,
    desiredOpportunities,
    setDesiredOpportunities,
    retirementGoals,
    setRetirementGoals,
    desiredLocation,
    setDesiredLocation,
    lastRoleType,
    lastRoleTypeValues,
    setLastRoleType,
    linkedInProfile,
    setLinkedInProfile,
    twitterProfile,
    setTwitterProfile,
    facebookProfile,
    setFacebookProfile,
    seniorityLevel,
    seniorityLevels,
    setSeniorityLevel,
    seekingEducation,
    seekingEducationValues,
    setSeekingEducation,
    interestIsRetirement,
    interestIsWorkingForSelf,
    interestIsJobSeeking,
    serverTranslations,
  } = useData()
  const { t } = useTranslation()
  const { linkedInProfileLabel, twitterProfileLabel, facebookProfileLabel } =
    serverTranslations

  return (
    <div className="mb-4 grid grid-cols-2 gap-x-7 gap-y-4">
      <div className="col-span-2 my-6">
        <label>{t('interested_in')}</label>
        <InterestSelect />
        <input
          type="hidden"
          name="user[candidate_profile_attributes][interest]"
          value={[interest]}
        />
      </div>
      {interestIsJobSeeking && (
        <div className="col-span-2 sm:col-span-1">
          <label>{t('step3.desired_title')}</label>
          <input
            type="text"
            className="form-control w-full"
            name="user[candidate_profile_attributes][job_title]"
            value={desiredJobTitle}
            onChange={(e) => setDesiredJobTitle(e.target.value)}
          />
        </div>
      )}
      {interestIsWorkingForSelf && (
        <div className="col-span-2 sm:col-span-1">
          <label>{t('step3.desired_opportunities')}</label>
          <input
            type="text"
            className="form-control w-full"
            name="user[candidate_profile_attributes][desired_opportunities]"
            value={desiredOpportunities}
            onChange={(e) => setDesiredOpportunities(e.target.value)}
          />
        </div>
      )}
      {(interestIsJobSeeking || interestIsWorkingForSelf) && (
        <div className="col-span-2 sm:col-span-1">
          <label>{t('step3.desired_industry')}</label>
          <input
            type="text"
            className="form-control w-full"
            name="user[candidate_profile_attributes][industry]"
            value={desiredIndustry}
            onChange={(e) => setDesiredIndustry(e.target.value)}
          />
        </div>
      )}
      {interestIsJobSeeking && (
        <div className="col-span-2">
          <label>{t('step3.about_yourself')}</label>
          <textarea
            type="text"
            className="form-control w-full"
            name="user[candidate_profile_attributes][about_yourself]"
            value={aboutYourself}
            onChange={(e) => setAboutYourself(e.target.value)}
          />
        </div>
      )}
      {interestIsRetirement && (
        <div className="col-span-2">
          <label>{t('step3.retirement_goals')}</label>
          <textarea
            type="text"
            className="form-control w-full"
            name="user[candidate_profile_attributes][retirement_goals]"
            value={retirementGoals}
            onChange={(e) => setRetirementGoals(e.target.value)}
          />
        </div>
      )}
      <div className="col-span-2 sm:col-span-1">
        <label>{t('step3.desired_location')}</label>
        <input
          type="text"
          className="form-control w-full"
          name="user[candidate_profile_attributes][location]"
          value={desiredLocation}
          onChange={(e) => setDesiredLocation(e.target.value)}
        />
      </div>
      <div className="col-span-2 sm:col-span-1">
        <label>{t('step4.last_role_type.description')}</label>
        <select
          className="form-control w-full"
          name="user[candidate_profile_attributes][last_role_type]"
          value={lastRoleType}
          onChange={(e) => setLastRoleType(e.target.value)}
        >
          {lastRoleTypeValues.map(([id, value]) => (
            <option key={id} value={id}>
              {t(`step4.last_role_type.${id}`)}
            </option>
          ))}
        </select>
      </div>
      <div className="col-span-2 sm:col-span-1">
        <label>
          <br className="hidden sm:!visible sm:!block" />{' '}
          {t('step4.seniority.description')}{' '}
        </label>
        <select
          className="form-control w-full"
          name="user[seniority_level]"
          value={seniorityLevel}
          onChange={(e) => setSeniorityLevel(e.target.value)}
        >
          {seniorityLevels.map(([id, value]) => (
            <option key={id} value={value}>
              {t(`step4.seniority.${id}`)}
            </option>
          ))}
        </select>
      </div>
      <div className="col-span-2 sm:col-span-1">
        <label>{t('step4.seeking_education')} </label>
        <select
          className="form-control w-full"
          name="user[candidate_profile_attributes][seeking_education]"
          value={seekingEducation}
          onChange={(e) => setSeekingEducation(e.target.value)}
        >
          {seekingEducationValues.map((value) => (
            <option key={value} value={value}>
              {t(`step4.education_${value}`)}
            </option>
          ))}
        </select>
      </div>
      <div className="col-span-2">
        <label>{linkedInProfileLabel}</label>
        <input
          type="text"
          className="form-control w-full"
          name="user[networking_social_media_profile][linked_in_profile_url]"
          value={linkedInProfile}
          onChange={(e) => setLinkedInProfile(e.target.value)}
        />
      </div>
      <div className="col-span-2">
        <label>{twitterProfileLabel}</label>
        <input
          type="text"
          className="form-control w-full"
          name="user[networking_social_media_profile][twitter_profile_url]"
          value={twitterProfile}
          onChange={(e) => setTwitterProfile(e.target.value)}
        />
      </div>
      <div className="col-span-2">
        <label>{facebookProfileLabel}</label>
        <input
          type="text"
          className="form-control w-full"
          name="user[networking_social_media_profile][facebook_profile_url]"
          value={facebookProfile}
          onChange={(e) => setFacebookProfile(e.target.value)}
        />
      </div>
    </div>
  )
}

const MyAccountSettings = ({ locale = null, ...props }) => {
  const scope = 'javascript.components.OnboardingSteps'

  return (
    <TranslationProvider
      translations={translations}
      locale={locale || 'en'}
      scope={scope}
    >
      <DataProvider fetchOnLoad>
        <MyAccountSettingsInternal {...props} />
      </DataProvider>
    </TranslationProvider>
  )
}

export default MyAccountSettings
