import React, { useEffect, useState } from 'react'
import Results from './Results'
import StartQuiz from './StartQuiz'
import Quiz from './Quiz'
import { TranslationProvider } from '../shared/TranslationProvider'
import translations from './locales.json'
const QuizComponents = ({
  quizId,
  quizPartId,
  content,
  quizName,
  startPageAvatar,
  resultsPageAvatar,
  actions,
  updateUserTracker,
  setTasks,
  setSlugs,
  slugs,
  startQuizContent,
  tasks,
  time,
  locale,
  bucketPath,
}) => {
  const [started, setStarted] = useState(
    localStorage.getItem('started') === 'true' || false,
  )
  const [showResults, setShowResults] = useState(
    localStorage.getItem('results') === 'true' || false,
  )
  const [documentId, setDocumentId] = useState()
  const [documentPartId, setDocumentPartId] = useState()
  const [pageRenderedOnce, setPageRenderedOnce] = useState(false)
  const [trackContent, setTrackContent] = useState({})
  const [quizIndex, setQuizIndex] = useState(0)
  const formattedQuizName = quizName.toLowerCase().split(' ').join('_')
  const [resultsTasks, setResultsTasks] = useState([])
  // const questions = Object.values(questionsInfo).map(
  //   (questionItem) => questionItem.question,
  // )
  // const numQuestions = questions.length

  useEffect(() => {
    if (quizId) {
      setDocumentId(quizId)
      setDocumentPartId(quizPartId)
      if (content) {
        if (content.content) setTrackContent(content.content)
        if (content.tasks) setTasks(content.tasks)
        if (content.slugs) setSlugs(new Set(content.slugs))
      }
    }
    if (showResults) {
      if (content && content.tasks) setResultsTasks(content.tasks)
    }
  }, [])

  useEffect(() => {
    const handleReload = (e) => {
      if (started) {
        e.preventDefault()
        e.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', handleReload)
    return () => {
      window.removeEventListener('beforeunload', handleReload)
    }
  }, [])

  useEffect(() => {
    if (quizPartId) {
      localStorage.setItem('started', started)
      localStorage.setItem('results', showResults)
    }
  }, [started, showResults, quizIndex])

  useEffect(() => {
    if (!pageRenderedOnce && Object.keys(trackContent).length > 0) {
      setQuizIndex(Object.keys(trackContent).length - 1)
    }
    return () => {
      setPageRenderedOnce(true)
    }
  }, [trackContent])

  // useEffect(() => {
  //   if (!started && Object.keys(trackContent).length > 0) {
  //     setQuizIndex(parseInt(Object.keys(trackContent).pop()) - 1)
  //   }
  //   calcScore(trackContent)
  // }, [trackContent])

  const saveQuiz = async (results = false) => {
    const token = document.querySelector('meta[name="csrf-token"]').content

    const url = documentId
      ? `/documents/${documentId}/parts/${documentPartId}`
      : `/quiz/document/quizzes?quiz_type=${formattedQuizName}`

    if (!documentId) {
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': token,
        },
      })
      if (res.ok) {
        setStarted(true)
        const data = await res.json()
        setDocumentId(data.document_id)
        setDocumentPartId(data.document_part_id)
        return res
      }
    } else {
      const documentPartsInfo = { content: { content: trackContent } }
      documentPartsInfo.content['tasks'] = tasks
      documentPartsInfo.content['slugs'] = Array.from(slugs)
      if (results) {
        setResultsTasks(tasks)
        setTasks([])
      }
      // else {
      //   documentPartsInfo.content['tasks'] = tasks
      //   documentPartsInfo.content['slugs'] = Array.from(slugs)
      // }
      const res = await fetch(url, {
        method: 'PATCH',
        headers: {
          'X-CSRF-TOKEN': token,
          'Content-type': 'application/json',
        },
        body: JSON.stringify(documentPartsInfo),
      })
      if (res.ok) {
        if (results) {
          // updateUserTracker()
          setShowResults(true)
          // calcScore(trackContent)
          document.getElementsByTagName('body')[0].classList.add('quiz-results')
        } else window.location.pathname = '/'
        return res
      }
    }
    throw new Error('res not okay')
  }

  const functionalProps = {
    saveQuiz,
    setQuizIndex,
    setStarted,
    trackContent,
    setTrackContent,
    setTasks,
    tasks,
    bucketPath,
  }

  return (
    <TranslationProvider
      locale={locale || 'en'}
      translations={translations}
      scope={'javascript.components.Quizzes'}
    >
      <div className="quiz flex justify-center border-l border-[#DDD] px-4 py-6">
        {showResults && (
          <Results
            formattedQuizName={formattedQuizName}
            quizName={quizName}
            tasks={resultsTasks}
            avatar={resultsPageAvatar}
            setShowResults={setShowResults}
            trackContent={trackContent}
          />
        )}
        {!started && !showResults && (
          <StartQuiz
            startQuizContent={startQuizContent}
            avatar={startPageAvatar}
            quizExists={!!documentId}
            time={time}
            formattedQuizName={formattedQuizName}
            {...functionalProps}
          />
        )}
        {started && !showResults && (
          <Quiz
            quizIndex={quizIndex}
            formattedQuizName={formattedQuizName}
            {...functionalProps}
          />
        )}
      </div>
    </TranslationProvider>
  )
}
export default QuizComponents
