import React from 'react'

export const StockImageTop = () => {
  return (
    <img
      src="https://www-yns-com.s3.us-east-2.amazonaws.com/images/platform/logged_out_experience/600332054_sm.jpeg"
      alt=""
    />
  )
}

export const StockImageBottom = () => {
  return (
    <img
      src="https://www-yns-com.s3.us-east-2.amazonaws.com/images/platform/logged_out_experience/138828889_sm.jpeg"
      alt=""
    />
  )
}

export const LogoBackgroundRight = () => {
  return (
    <svg
      className="logo-background-right logo-background"
      width="189"
      height="291"
      viewBox="0 0 189 291"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M141.053 134.824C124.79 132.785 110.772 128.386 99.293 121.923C87.864 115.566 78.8985 107.194 72.5057 97.1119C64.9901 85.443 61.0468 71.3678 60.84 55.4583C60.7772 47.9932 63.3449 42.0648 71.1928 41.5973C79.339 41.1977 82.5206 49.2357 82.9705 57.9199C83.3508 65.5248 84.4634 72.3997 86.3798 78.6881C86.4096 79.0042 86.5606 79.3219 86.6863 79.6882C90.2657 90.9923 96.5197 100.065 105.686 106.421C113.421 111.904 123.231 115.54 135.32 117.125C179.244 122.924 195.228 84.5101 173.543 69.1054C197.104 84.5029 178.876 136.341 141.053 134.824Z"
        fill="url(#paint0_linear_477_3724)"
      />
      <path
        d="M166.291 237.556C101.69 234.617 48.9096 194.249 62.7813 122.269C64.4435 113.578 67.8427 105.098 72.5192 97.1012C78.8948 107.201 87.863 115.583 99.2838 121.909C98.3596 124.669 97.629 127.556 97.0032 130.445C96.7482 131.46 96.5416 132.492 96.395 133.502C87.3906 182.014 122.412 236.907 175.296 237.677C172.3 237.725 169.302 237.666 166.291 237.556Z"
        fill="url(#paint1_linear_477_3724)"
      />
      <path
        d="M171.508 67.7917C170.994 67.5138 170.54 67.2143 169.995 66.9816C141.979 51.4781 108.73 59.4895 86.6948 79.7101C86.5564 79.3371 86.3998 78.9988 86.3964 78.6789C114.151 48.9222 159.4 33.012 190.288 55.7908C223.26 80.0938 205.353 142.961 141.066 134.822C178.869 136.346 197.103 84.5112 173.556 69.1031C173.436 68.9432 173.221 68.8569 173.058 68.7802C172.559 68.4573 172.054 68.1311 171.508 67.7917Z"
        fill="url(#paint2_linear_477_3724)"
      />
      <path
        d="M169.988 66.9757C150.991 57.7241 121.428 76.0265 105.679 106.424C96.5072 100.047 90.2515 91.0086 86.6885 79.7042C108.731 59.487 141.973 51.4723 169.988 66.9757Z"
        fill="url(#paint3_linear_477_3724)"
      />
      <path
        d="M292.138 135.246C303.655 144.389 307.883 172.7 284.725 194.427C263.003 214.795 221.684 236.926 175.346 237.693L175.301 237.669C122.414 236.907 87.3924 182.013 96.4005 133.495C89.5449 172.774 114.673 209.053 148.93 209.391C202.847 209.959 247.243 144.635 247.243 144.635C257.921 130.449 276.569 122.868 292.138 135.246Z"
        fill="url(#paint4_linear_477_3724)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_477_3724"
          x1="173.494"
          y1="120.164"
          x2="61.3864"
          y2="48.5142"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00541A" />
          <stop offset="0.319" stopColor="#7DC24B" />
          <stop offset="0.4591" stopColor="#77BD4A" />
          <stop offset="0.6087" stopColor="#6AB346" />
          <stop offset="0.7627" stopColor="#53A441" />
          <stop offset="0.9188" stopColor="#318F3A" />
          <stop offset="1" stopColor="#148335" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_477_3724"
          x1="153.676"
          y1="56.0951"
          x2="110.936"
          y2="232.503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00152F" />
          <stop offset="0.1168" stopColor="#004260" />
          <stop offset="0.2361" stopColor="#006992" />
          <stop offset="0.3373" stopColor="#0088BA" />
          <stop offset="0.4151" stopColor="#009CD6" />
          <stop offset="0.4601" stopColor="#00A5E5" />
          <stop offset="0.5169" stopColor="#009FDB" />
          <stop offset="0.5919" stopColor="#0093CA" />
          <stop offset="0.6773" stopColor="#0080B0" />
          <stop offset="0.7703" stopColor="#00668E" />
          <stop offset="0.8694" stopColor="#004767" />
          <stop offset="0.9722" stopColor="#00233C" />
          <stop offset="1" stopColor="#00152F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_477_3724"
          x1="79.818"
          y1="72.3104"
          x2="213.607"
          y2="109.86"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005C97" />
          <stop offset="0.5" stopColor="#00AAD3" />
          <stop offset="1" stopColor="#005C97" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_477_3724"
          x1="134.578"
          y1="51.1139"
          x2="90.7547"
          y2="231.994"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00152F" />
          <stop offset="0.5" stopColor="#00AAD3" />
          <stop offset="1" stopColor="#002055" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_477_3724"
          x1="111.387"
          y1="199.001"
          x2="314.925"
          y2="143.98"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00541A" />
          <stop offset="0.0882" stopColor="#0F6524" />
          <stop offset="0.2364" stopColor="#347F31" />
          <stop offset="0.3871" stopColor="#4E973B" />
          <stop offset="0.5383" stopColor="#62A942" />
          <stop offset="0.6903" stopColor="#70B647" />
          <stop offset="0.8435" stopColor="#79BE4A" />
          <stop offset="1" stopColor="#7DC24B" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const LogoBackgroundLeft = () => {
  return (
    <svg
      className="logo-background-left logo-background"
      width="273"
      height="354"
      viewBox="0 0 273 354"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M141.374 149C151.056 131.887 156.859 115.245 158.859 99.5685C160.924 84.0161 159.286 69.392 154.159 56.0195C148.31 40.4302 137.586 26.556 122.421 14.9647C115.281 9.55887 107.736 7.78515 101.667 15.004C95.4491 22.5585 100.907 31.3765 108.944 38.028C115.991 43.8396 121.812 49.8333 126.492 56.1807C126.775 56.4357 126.972 56.8086 127.235 57.1918C135.552 68.7314 139.806 81.2476 139.36 94.6217C139.099 105.993 135.573 118.038 128.443 130.809C102.572 177.238 54.1536 165.116 54.8543 133.213C52.8032 166.916 115.749 186.491 141.374 149Z"
        fill="url(#paint0_linear_477_2891)"
      />
      <path
        d="M222.18 246.853C265.609 182.571 264.548 102.864 185.335 64.6731C175.78 60.0494 165.184 57.2491 154.138 56.0242C159.294 69.3927 160.94 84.0267 158.851 99.5492C162.169 100.635 165.471 102 168.7 103.466C169.859 103.948 171 104.488 172.078 105.07C225.218 131.14 252.975 204.156 215.848 255.607C218.039 252.758 220.129 249.831 222.18 246.853Z"
        fill="url(#paint1_linear_477_2891)"
      />
      <path
        d="M55.0471 130.314C55.1475 129.62 55.1841 128.969 55.3508 128.277C60.4895 90.2114 92.0086 63.9462 127.25 57.2161C126.99 56.8158 126.776 56.4229 126.471 56.1906C77.9561 61.5966 30.2413 93.7562 30.0481 139.797C29.83 188.934 103.162 216.716 141.362 149.012C115.759 186.488 52.8114 166.922 54.8425 133.225C54.7744 132.995 54.8455 132.726 54.8886 132.514C54.9347 131.803 54.9822 131.083 55.0471 130.314Z"
        fill="url(#paint2_linear_477_2891)"
      />
      <path
        d="M55.3509 128.268C60.0497 103.358 98.834 88.0097 139.369 94.6174C139.799 81.2233 135.579 68.7301 127.25 57.2061C92.0074 63.9447 60.4896 90.2015 55.3509 128.268Z"
        fill="url(#paint3_linear_477_2891)"
      />
      <path
        d="M33.5931 294.721C34.146 312.352 58.3676 336.695 95.8626 329.963C131.02 323.641 181.908 299.718 215.828 255.667L215.837 255.607C252.973 204.157 225.216 131.142 172.067 105.07C214.782 126.598 231.708 176.762 207.503 209.976C169.442 262.278 74.7767 258.233 74.7767 258.233C53.4771 258.352 32.8273 270.872 33.5931 294.721Z"
        fill="url(#paint4_linear_477_2891)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_477_2891"
          x1="104.032"
          y1="169.727"
          x2="115.346"
          y2="10.5182"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00541A" />
          <stop offset="0.319" stopColor="#7DC24B" />
          <stop offset="0.4591" stopColor="#77BD4A" />
          <stop offset="0.6087" stopColor="#6AB346" />
          <stop offset="0.7627" stopColor="#53A441" />
          <stop offset="0.9188" stopColor="#318F3A" />
          <stop offset="1" stopColor="#148335" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_477_2891"
          x1="56.5567"
          y1="104.775"
          x2="256.953"
          y2="189.956"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00152F" />
          <stop offset="0.1168" stopColor="#004260" />
          <stop offset="0.2361" stopColor="#006992" />
          <stop offset="0.3373" stopColor="#0088BA" />
          <stop offset="0.4151" stopColor="#009CD6" />
          <stop offset="0.4601" stopColor="#00A5E5" />
          <stop offset="0.5169" stopColor="#009FDB" />
          <stop offset="0.5919" stopColor="#0093CA" />
          <stop offset="0.6773" stopColor="#0080B0" />
          <stop offset="0.7703" stopColor="#00668E" />
          <stop offset="0.8694" stopColor="#004767" />
          <stop offset="0.9722" stopColor="#00233C" />
          <stop offset="1" stopColor="#00152F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_477_2891"
          x1="125.052"
          y1="45.2986"
          x2="65.3925"
          y2="200.958"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005C97" />
          <stop offset="0.5" stopColor="#00AAD3" />
          <stop offset="1" stopColor="#005C97" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_477_2891"
          x1="65.4397"
          y1="82.8275"
          x2="270.916"
          y2="170.168"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00152F" />
          <stop offset="0.5" stopColor="#00AAD3" />
          <stop offset="1" stopColor="#002055" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_477_2891"
          x1="224.386"
          y1="166.401"
          x2="25.6825"
          y2="322.907"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00541A" />
          <stop offset="0.0882" stopColor="#0F6524" />
          <stop offset="0.2364" stopColor="#347F31" />
          <stop offset="0.3871" stopColor="#4E973B" />
          <stop offset="0.5383" stopColor="#62A942" />
          <stop offset="0.6903" stopColor="#70B647" />
          <stop offset="0.8435" stopColor="#79BE4A" />
          <stop offset="1" stopColor="#7DC24B" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const LatitudeLogo = () => {
  return (
    <img
      className="branding-logo"
      src="https://www-yns-com.s3.us-east-2.amazonaws.com/images/Latitude_Logo_Vertical_White.png"
      alt=""
    />
  )
}
