import React from 'react'
import parse from 'html-react-parser'
import { cn } from '../../lib/utils'
import HighlightCommentProvider, {
  useHighlightComment,
} from './HighlightCommentProvider'
import CommentBox from './CommentBox'

export const NumberedList = ({ listObject }) => {
  return (
    <List
      titleClass="font-semibold"
      listClass="list-decimal font-semibold"
      listObject={listObject}
    />
  )
}

export const TitledBulletList = ({ listObject }) => {
  return (
    <List
      titleClass="font-semibold"
      listClass="list-outside list-disc space-y-4"
      listObject={listObject}
    />
  )
}

export const TitledList = ({ listObject }) => {
  return (
    <List
      titleClass="font-semibold"
      listClass="list-outside space-y-4 ml-0"
      listObject={listObject}
    />
  )
}

export const BulletedList = ({ listObject }) => {
  return (
    <List
      divClass="mx-4"
      titleClass="font-semibold"
      listClass="list-outside list-disc"
      listObject={listObject}
    />
  )
}

export const List = ({ divClass, titleClass, listClass, listObject }) => {
  const { content, children, title } = listObject
  return (
    <div className={cn('mt-8 first:mt-0', divClass)}>
      <div className={cn('mb-4', titleClass)}>{title}</div>
      <ul className={cn('ml-4 list-inside space-y-2', listClass)}>
        {children && (
          <HighlightCommentProvider locatorSuffix=".children">
            <ListItems items={children} />
          </HighlightCommentProvider>
        )}
        {content && (
          <HighlightCommentProvider locatorSuffix=".content">
            <ListItems items={content} />
          </HighlightCommentProvider>
        )}
      </ul>
    </div>
  )
}

export const ListItems = ({ items, plugins }) => {
  return (
    <>
      {items.map((item, index) => (
        <HighlightCommentProvider key={index} locatorSuffix={`[${index}]`}>
          <ListItem plugins={plugins} item={item} />
        </HighlightCommentProvider>
      ))}
    </>
  )
}

const ListItem = ({ plugins, item }) => {
  if (typeof item === 'string') {
    return <StringListItem plugins={plugins}>{item}</StringListItem>
  } else if (typeof item === 'object') {
    return <ObjectListItem item={item} />
  }
}

const StringListItem = ({ plugins, children }) => {
  const { actionProps, highlightClass } = useHighlightComment()
  return (
    <li className="">
      <span className={highlightClass} {...actionProps}>
        {plugins && plugins.includes('quoted') ? <q>{children}</q> : children}
      </span>
      <CommentBox />
    </li>
  )
}

const ObjectListItem = ({ item }) => {
  const { plugins, title, content, childPlugins, children } = item
  const { actionProps, highlightClass } = useHighlightComment()

  const text =
    plugins && plugins.includes('parse-html') ? parse(content) : content
  let divClass = highlightClass
  if (plugins) {
    divClass += plugins.join(' ')
  }
  return (
    <li>
      <div className="font-semibold">{title}</div>
      <div className={divClass} {...actionProps}>
        {text}
      </div>
      <CommentBox />
      {children && (
        <ul className="ml-4 mt-4 list-inside list-disc space-y-2">
          <HighlightCommentProvider locatorSuffix=".children">
            <ListItems items={children} plugins={childPlugins} />
          </HighlightCommentProvider>
        </ul>
      )}
    </li>
  )
}
