import React, { useEffect, useState } from 'react'
import { cn } from '../../lib/utils'

const Response = ({
  responseType,
  showResponse,
  response,
  alreadyTransitioned,
}) => {
  const [topLine, setTopLine] = useState()
  const [paragraph, setParagraph] = useState('')
  const [backgroundColor, setBackgroundColor] = useState('')
  const [transform, setTransform] = useState('')

  useEffect(() => {
    if (!alreadyTransitioned) {
      setTransform('transform -translate-y-32')
    }
  }, [alreadyTransitioned])

  useEffect(() => {
    if (showResponse) {
      setTransform('')
    }
    if (response) {
      if (response.length > 1) {
        setTopLine(response[0])
        setParagraph(response[1])
      } else {
        setTopLine()
        setParagraph(response[0])
      }
      setBackgroundColor(responseType == 0 ? 'bg-[#F3FFEB]' : 'bg-[#FCF4DA]')
    }
  }, [response, showResponse, responseType])

  return (
    showResponse && (
      <div
        className={cn(
          backgroundColor,
          transform,
          'mt-4 flex flex-col gap-3 rounded-[20px] border border-solid border-[#b9b9b924] p-4 duration-1000 sm:w-8/12 sm:justify-center',
        )}
      >
        {topLine && (
          <div style={{ fontFamily: 'gotham_boldregular' }}>{topLine}</div>
        )}
        <div>{paragraph}</div>
      </div>
    )
  )
}
export default Response
