import * as React from 'react'

import { useSavedJobs } from './SavedJobsProvider'
import { columns } from './columns'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../lib/ui/table'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useTranslation } from '../../../hooks/useTranslation'
import { useQueryClient } from '@tanstack/react-query'

export function DataTable() {
  const { t } = useTranslation('DataTable')
  const {
    jobs,
    sortBy: {
      setSorting,
      sorting,
      setColumnFilters,
      columnFilters,
      statusSortingOrder,
    },
  } = useSavedJobs()

  const [columnVisibility, setColumnVisibility] = React.useState({
    updated_at: false,
  })

  const isDesktop = useIsDesktop()

  React.useEffect(() => {
    if (isDesktop) {
      setColumnVisibility({
        title: true,
        created_at: true,
        updated_at: false,
        followup_on: true,
        status: true,
      })
    } else {
      setColumnVisibility({
        title: true,
        created_at: false,
        updated_at: false,
        followup_on: false,
        status: true,
      })
    }
  }, [isDesktop])

  const queryClient = useQueryClient()

  const table = useReactTable({
    debugTable: true,
    data: jobs,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      columnVisibility,
      sorting,
      columnFilters,
    },
    enableMultiSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    sortingFns: {
      statusSortingFn: (rowA, rowB, columnId) => {
        const valueA = rowA.original.status
        const valueB = rowB.original.status
        return (
          statusSortingOrder.indexOf(valueA) -
          statusSortingOrder.indexOf(valueB)
        )
      },
    },
    meta: {
      updateData: (rowIndex, columnId, value) => {
        queryClient.setQueryData(['get-saved-jobs'], (old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          }),
        )
      },
    },
  })

  const [lastUpdatedId, setLastUpdatedId] = React.useState()

  const handleRowClick = (row) => () => {
    setLastUpdatedId(row.id)
  }

  return (
    <div className="rounded-2xl border border-alto-200 p-4 sm:px-8 sm:py-6">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              key={headerGroup.id}
              className="border-alto-200 hover:bg-white"
            >
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    className="h-6 pl-0 text-sm font-bold text-foreground data-[col-id=status]:w-[96px] sm:h-12 sm:px-4 sm:text-base data-[col-id=status]:sm:w-[120px]"
                    data-col-id={header.column.id}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                fred={5}
                className="border-alto-200 hover:bg-transparent data-[last-updated=true]:bg-alto-50"
                data-last-updated={row.id === lastUpdatedId}
                onClick={handleRowClick(row)}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className="pb-2 pl-0 pr-0 pt-3 sm:p-4 sm:pr-1"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24">
                <div className="flex items-center justify-center">
                  <div className="max-w-96 text-center">
                    <h2 className="p-8">{t('no_saved_jobs_title')}</h2>
                    <p className="text-lg">{t('no_saved_jobs_message')}</p>
                    <p className="text-lg">
                      {t('to_get_started')},{' '}
                      <a href="/job-search/jobs">{t('explore_jobs')}</a>
                    </p>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
