import * as React from 'react'

import { useJobSearch } from './JobSearchProvider'
import Sanitize from '../Sanitize'
import { LinkedInButton, ApplyNowButton } from './buttons'
import { useTranslation } from '../../../hooks/useTranslation'
import LeaveSiteWarning from '../../shared/LeaveSiteWarning'
import IndeedLogo from './IndeedLogo'
import SavedJobButton from './SavedJobButton'
import { useIsMobile } from '../../../hooks/useMediaQuery'

export default function JobDetails() {
  return (
    <div className="flex flex-col gap-4">
      <JobDetailsTop />
      <JobDetailsBottom />
    </div>
  )
}

export function JobDetailsTop() {
  const { selectedJob: job, debug, queryStatusMessage } = useJobSearch()
  const { t } = useTranslation('JobDetails')
  const isMobile = useIsMobile()

  if (!job) return null

  return (
    <>
      <div className="relative flex flex-row justify-between">
        {!!job.posted_on_month_day && (
          <div className="text-xs">
            {t('posted')} {job.posted_on_month_day}
          </div>
        )}

        <div className="absolute right-0 top-0 mt-[-0.75rem]">
          <SavedJobButton job={job} variant="icon" includeText />
        </div>
      </div>

      <div className="flex flex-col gap-1">
        <h2 className="text-xl font-extrabold">{job.title}</h2>

        <div className="flex items-center text-xs">
          <LeaveSiteWarning url={job.url}>
            <a className="">{job.company_name}</a>
          </LeaveSiteWarning>
          <span className="mx-2">•</span>
          <div>{job.location}</div>
        </div>
      </div>

      <div className="flex flex-wrap justify-start gap-3 sm:gap-4">
        <ApplyNowButton job={job} compact={isMobile} />
        <LinkedInButton job={job} compact={isMobile} />
        <SavedJobButton job={job} compact={isMobile} />
      </div>

      {debug && <pre className="break-all text-xs">{queryStatusMessage}</pre>}
    </>
  )
}

export function JobDetailsBottom() {
  const { selectedJob: job } = useJobSearch()

  if (!job) return null
  const isIndeed = job.job_publisher_name === 'Indeed'

  return (
    <div className="text-base">
      <Sanitize html={job.description} addMarkup />
      {isIndeed && (
        <LeaveSiteWarning url={job.url}>
          <div className="mb-4 mt-16 flex flex-row gap-1 text-sm text-intoo-blue-medium">
            {`jobs by`} <IndeedLogo className="-mt-1" />
          </div>
        </LeaveSiteWarning>
      )}
    </div>
  )
}
