import React from 'react'
const Deselected = ({ header, length, type }) => {
  return (
    <div>
      <div className="row tile-row">
        <div className="col-md-2" style={{ paddingLeft: '0px' }}>
          <div className="track-item-icon track-item-icon-small">
            <i className={type}></i>
          </div>
        </div>
        <div style={{ paddingLeft: '0px' }} className="col-md-10 tile-content">
          <div className="row">
            <div
              className="col-md-9 sub-column tile-description"
              style={{ paddingLeft: '25px' }}
            >
              {header}
            </div>
            <div
              className="col-md-3 sub-column"
              style={{ textAlign: 'center' }}
            >
              ({length})
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Deselected
