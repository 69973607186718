import React from 'react'
import { useData } from '../DataProvider'
import { FormControl, FormHelperText } from '@mui/joy'
import { Textarea } from '../../../lib/ui/textarea'
import DesiredLocation from './DesiredLocation'
import useTranslation from '../../shared/TranslationProvider'
import CharCounter from '../CharCounter'

const Step3Retirement = () => {
  const {
    retirementGoals,
    desiredLocation,
    setRetirementGoals,
    setDesiredLocation,
  } = useData()
  const { t } = useTranslation()
  const maxLength = 500
  return (
    <>
      <div className="flex flex-col">
        <FormControl>
          <label className="pb-2 text-base font-medium">
            {t('step3.retirement_goals')}:
          </label>
          <Textarea
            defaultValue={retirementGoals}
            onChange={(e) => setRetirementGoals(e.target.value)}
            rows={5}
            maxLength={maxLength}
          />
          <FormHelperText className="justify-end">
            <CharCounter value={retirementGoals} maxLength={maxLength} />
          </FormHelperText>
        </FormControl>
        <FormControl>
          <DesiredLocation
            desiredLocation={desiredLocation}
            onChange={(e) => setDesiredLocation(e.target.value)}
          />
        </FormControl>
      </div>
    </>
  )
}
export default Step3Retirement
