import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  createContext,
} from 'react'
import { useArticle } from './ArticleProvider'

const HighlightCommentContext = createContext()

export const useHighlightComment = () => {
  return useContext(HighlightCommentContext)
}

const HighlightCommentProvider = ({ locatorSuffix = '', children }) => {
  const parentLocator = useHighlightComment()?.locator || 'article'
  const locator = parentLocator + locatorSuffix
  const {
    isHighlightModeActive,
    setIsHighlightModeActive,
    isCommentModeActive,
    setIsCommentModeActive,
    updateHighlightList,
    highlightList,
    addComment,
    removeComment,
    commentsList,
  } = useArticle()

  const initialComment = commentsList[locator]?.comment || null
  const [comment, setComment] = useState(initialComment)
  const [editingComment, setEditingComment] = useState(false)
  const hasHighlight = highlightList.includes(locator)
  const hasComment = comment !== null
  const textAreaRef = useRef(null)

  const elementSelectable = isHighlightModeActive || isCommentModeActive

  const handleKeyDown = (e) => {
    if (!elementSelectable) return

    if ([13, 32].includes(e.keyCode)) {
      highlightThis()
      e.preventDefault()
    }
  }

  const handleClick = () => {
    highlightThis()
  }

  const highlightThis = () => {
    if (!isHighlightModeActive) {
      if (!isCommentModeActive) {
        return
      } else {
        setIsCommentModeActive(false)
        setEditingComment(true)
        setComment('')
      }
    } else {
      const isHighlighted = !hasHighlight
      updateHighlightList(locator, isHighlighted)
      setIsHighlightModeActive(false)
    }
  }

  useEffect(() => {
    editingComment && textAreaRef.current.focus()
  }, [editingComment])

  const deleteComment = () => {
    setComment(null)
    setEditingComment(false)
    initialComment && removeComment(locator)
  }

  const cancelComment = () => {
    setComment(initialComment)
    setEditingComment(false)
  }

  const editComment = () => {
    setEditingComment(true)
  }

  const saveComment = () => {
    if (!editingComment) {
      return
    }
    if (comment === '') {
      cancelComment()
      return
    }
    addComment(locator, comment)
    setEditingComment(false)
  }

  const removeHighlight = () => {
    updateHighlightList(locator, false)
    setIsHighlightModeActive(false)
    setIsCommentModeActive(false)
  }

  const backgroundClass = () => {
    if (hasHighlight && hasComment) {
      return 'bg-[#DBFFB7]'
    } else if (hasHighlight) {
      return 'bg-[#F1FF81]'
    } else if (hasComment) {
      return 'bg-[#EAF4FF]'
    }
  }

  const hightlightModeCursor = hasHighlight ? 'cursor-no-drop' : 'cursor-copy'
  const hightlightModeClass = isHighlightModeActive ? hightlightModeCursor : ''
  const commentModeClass = isCommentModeActive ? 'cursor-copy' : ''
  const highlightClass = `${hightlightModeClass} ${backgroundClass()} ${commentModeClass}`

  const actionProps = {
    onClick: handleClick,
    onKeyDown: handleKeyDown,
    tabIndex: elementSelectable ? 0 : -1,
  }

  const value = {
    locator,
    hasHighlight,
    highlightClass,
    handleKeyDown,
    elementSelectable,
    handleClick,
    actionProps,
    removeHighlight,
    comment,
    setComment,
    saveComment,
    deleteComment,
    cancelComment,
    editComment,
    setEditingComment,
    hasComment,
    editingComment,
    textAreaRef,
  }

  return (
    <HighlightCommentContext.Provider value={value}>
      {children}
    </HighlightCommentContext.Provider>
  )
}

export default HighlightCommentProvider
