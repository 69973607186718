import React from 'react'
import LoginForm from './LoginForm'
import TwoFactorEntry from './TwoFactorEntry'
import TwoFactorCreate from './TwoFactorCreate'
import InviteCodeEntry from './InviteCodeEntry'
import RegistrationForm from './RegistrationForm'
import ForgotPassword from './ForgotPassword'
import ExpiredPasswordReset from './ExpiredPasswordReset'
import AlertModal, { AlertProvider } from './AlertModal'
import AddLicense from './AddLicense'
import RightPanel from './RightPanel'
import AccountServices from './AccountServices'
import { Box } from '@mui/joy'
import FlashContainer from './FlashContainer'
import { DataProvider, useData } from './DataProvider'
import useTheme, { ThemeProvider } from '../../shared/ThemeProvider'
import { TranslationProvider } from '../../shared/TranslationProvider'
import translations from '../locales.json'

const LoggedOutExperience = ({
  inviteCode = '',
  initialFormState = 'LoginForm',
  googleAuthEnabled = true,
  appleAuthEnabled = true,
  panelAutoRotationDisabled = false,
  userNotificationEnabled = false,
  flashAlert = null,
  flashNotice = null,
  locale = null,
  appleOauthAttachEmail = null,
  googleOauthAttachEmail = null,
  legalNotice,
  newLegalNotice,
}) => {
  return (
    <AlertProvider>
      <TranslationProvider
        locale={locale || 'en'}
        translations={translations}
        scope="javascript.components.visitor.LoggedOutExperience"
      >
        <ThemeProvider>
          <DataProvider
            initialFormState={initialFormState}
            inviteCode={inviteCode}
            googleAuthEnabled={googleAuthEnabled}
            appleAuthEnabled={appleAuthEnabled}
            panelAutoRotationDisabled={panelAutoRotationDisabled}
            userNotificationEnabled={userNotificationEnabled}
            flashAlert={flashAlert}
            flashNotice={flashNotice}
            locale={locale}
            appleOauthAttachEmail={appleOauthAttachEmail}
            googleOauthAttachEmail={googleOauthAttachEmail}
            legalNotice={legalNotice}
            newLegalNotice={newLegalNotice}
          >
            <LoggedOutContainer />
          </DataProvider>
        </ThemeProvider>
      </TranslationProvider>
    </AlertProvider>
  )
}

export default LoggedOutExperience

const LoggedOutContainer = () => {
  const { formState } = useData()
  const { secondaryColor } = useTheme()
  return (
    <div className="flex min-h-screen flex-wrap">
      <div className="flex flex-grow basis-3/5 justify-around">
        <Box
          className="w-auto px-6 sm:w-[33.125rem] sm:px-0"
          sx={{
            'div.link-container': {
              '& a': {
                color: `var(--linkColor, ${secondaryColor})`,
              },
            },
          }}
        >
          <FlashContainer />
          <AlertModal />
          <div>
            {formState === 'LoginForm' && <LoginForm />}
            {formState === 'TwoFactorEntry' && <TwoFactorEntry />}
            {formState === 'TwoFactorCreate' && <TwoFactorCreate />}
            {formState === 'InviteCodeEntry' && <InviteCodeEntry />}
            {formState === 'RegistrationForm' && <RegistrationForm />}
            {formState === 'ForgotPassword' && <ForgotPassword />}
            {formState === 'AddLicense' && <AddLicense />}
            {formState === 'AccountServices' && <AccountServices />}
            {formState === 'ExpiredPasswordReset' && <ExpiredPasswordReset />}
          </div>
        </Box>
      </div>
      <RightPanel />
    </div>
  )
}
