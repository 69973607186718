import React, { useState } from 'react'
import { Box, Button } from '@mui/joy'
import SetPassword from '../SetPassword/SetPassword'
import { getApi } from '../../../../util/api'
import ContactUsLink from '../ContactUsLink'
import AlertModal, { AlertProvider, useAlert } from '../AlertModal'
import { SpinnerWhite } from '../../../shared/Spinner'
import useTranslation, {
  TranslationProvider,
} from '../../../shared/TranslationProvider.js'
import translations from '../../locales.json'

const ResetPassword = ({ locale, ...props }) => {
  return (
    <TranslationProvider
      locale={locale || 'en'}
      translations={translations}
      scope="javascript.components.visitor.LoggedOutExperience"
    >
      <AlertProvider>
        <ResetPasswordInner {...props} />
      </AlertProvider>
    </TranslationProvider>
  )
}

const ResetPasswordInner = ({ email, resetPasswordToken }) => {
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { setAlertData, setAlertState } = useAlert()
  const { t } = useTranslation()

  const handleResetPassword = async () => {
    const user = {
      reset_password_token: resetPasswordToken,
      password: password,
      password_confirmation: passwordConfirmation,
    }
    setIsLoading(true)
    try {
      const response = await getApi().put('/users/password', { user: user })
      if (response.data.errors) {
        if (typeof response.data.errors[0] === 'string') {
          setAlertData(response.data.errors[0])
          setAlertState('alert')
        } else {
          setErrors(response.data.errors)
        }
        setIsLoading(false)
      } else {
        window.location.href = response.data.location
      }
    } catch (error) {
      setIsLoading(false)
      setAlertData(t('ResetPassword.error'))
      setAlertState('alert')
    }
  }

  return (
    <Box className="reset-password">
      <AlertModal />
      <Box
        sx={{
          h1: {
            textTransform: 'capitalize',
            fontSize: '2.5rem',
          },
        }}
      >
        <h1>{t('ResetPassword.title')}</h1>
      </Box>

      <Box className="subtext-container">
        {t('ResetPassword.enter_new_password')} {email}.
      </Box>
      <SetPassword
        password={password}
        setPassword={setPassword}
        passwordConfirmation={passwordConfirmation}
        setPasswordConfirmation={setPasswordConfirmation}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleResetPassword()
        }}
        errors={errors}
      />
      <Box className="button-container padding">
        <Button
          className="submit-button btn"
          size="lg"
          fullWidth
          onClick={handleResetPassword}
          disabled={isLoading}
        >
          {t('ResetPassword.reset_password')}
          <SpinnerWhite show={isLoading} className="pl-4" />
        </Button>
      </Box>
      <ContactUsLink />
    </Box>
  )
}
export default ResetPassword
