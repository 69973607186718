import React, { createContext, useContext, useState } from 'react'
import { apiPatch } from '../../util/api'

const ResourcesContext = createContext()

export const useResources = () => {
  return useContext(ResourcesContext)
}

const ResourcesProvider = ({
  serialized_article_list,
  serialized_audio_list,
  serialized_quiz_list,
  favorite_article_ids,
  children,
}) => {
  const articles = serialized_article_list
  const audioFiles = serialized_audio_list
  const quizzes = serialized_quiz_list
  const [favorites, setFavorites] = useState(favorite_article_ids || [])
  const [activity, setActivity] = useState('articles')

  const currentPath =
    window.location.pathname.slice(-1) === '/'
      ? window.location.pathname.slice(0, -1)
      : window.location.pathname

  const toggleFavorite = (article) => {
    const newIsFavorite = !checkFavorite(article)
    if (newIsFavorite) {
      setFavorites([...favorites, article.id])
    } else {
      setFavorites(favorites.filter((id) => id !== article.id))
    }
    patchFavorite(article, newIsFavorite)
  }

  const getArticlePath = (article) => {
    return `${currentPath}/article/${article.slug}`
  }

  const patchFavorite = async (article, isFavorite) => {
    const articlePath = getArticlePath(article)
    const data = { is_favorite: isFavorite }
    try {
      const response = await apiPatch(articlePath, {
        candidate_article: data,
      })
      if (response.status !== 200) {
        console.error(
          'Updating CandidateArticle failed with response',
          response,
        )
      }
    } catch (error) {
      console.error('Error updating CandidateArticle', error)
    }
  }

  const openArticlesTab = () => {
    setActivity('articles')
  }
  const articlesActive = activity === 'articles'

  const openAudioTab = () => {
    setActivity('audio')
  }
  const audioActive = activity === 'audio'

  const openFavoritesTab = () => {
    setActivity('favorites')
  }
  const favoritesActive = activity === 'favorites'

  const openExercisesTab = () => {
    setActivity('exercises')
  }
  const exercisesActive = activity === 'exercises'

  const openQuizzesTab = () => {
    setActivity('quizzes')
  }
  const quizzesActive = activity === 'quizzes'

  const openAssessmentsTab = () => {
    setActivity('assessments')
  }
  const assessmentsActive = activity === 'assessments'

  const openWebinarsTab = () => {
    setActivity('webinars')
  }
  const webinarsActive = activity === 'webinars'

  const checkFavorite = (article) => {
    return favorites.includes(article.id)
  }

  const value = {
    articles,
    checkFavorite,
    toggleFavorite,
    openArticlesTab,
    openAudioTab,
    openFavoritesTab,
    openQuizzesTab,
    openAssessmentsTab,
    openWebinarsTab,
    openExercisesTab,
    articlesActive,
    audioActive,
    audioFiles,
    quizzes,
    favoritesActive,
    quizzesActive,
    assessmentsActive,
    webinarsActive,
    exercisesActive,
    getArticlePath,
  }

  return (
    <ResourcesContext.Provider value={value}>
      {children}
    </ResourcesContext.Provider>
  )
}

export default ResourcesProvider
