import * as React from 'react'

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { api } from '../../../util/api'
import { Button } from '../../../lib/ui/button'
import { useTranslation } from '../../../hooks/useTranslation'
import { useJobSearch } from './JobSearchProvider'
import { useSavedJobs } from '../SavedJobsPage/SavedJobsProvider'

export default function SavedJobButton({ job, variant, includeText }) {
  const queryClient = useQueryClient()
  const [jobPostingIsSaved, setJobPostingIsSaved] = React.useState(false)
  const { savedJobIds, setSavedJobIds } = useJobSearch()
  const { t } = useTranslation('SaveJobButton')
  const { postSavedJobMutation } = useSavedJobs()

  const { data, isPending } = useQuery({
    queryKey: ['saved-job-ids'],
    queryFn: getSavedJobIds,
  })
  React.useEffect(() => {
    if (!isPending && data) {
      setSavedJobIds(data)
    }
  }, [data, isPending, setSavedJobIds])

  React.useEffect(() => {
    setJobPostingIsSaved(savedJobIds.includes(job.id))
  }, [savedJobIds, job.id])

  const mutation = useMutation({
    mutationFn: () => postSavedJob({ job_posting_id: job.id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['saved-job-ids'] })
    },
  })

  const saveJobPosting = async () => {
    setJobPostingIsSaved(true)
    setSavedJobIds((prevSavedJobIds) => [...prevSavedJobIds, job.id])
    mutation.mutate()
  }

  const saveApiJobAsOutsideJob = async () => {
    setJobPostingIsSaved(true)
    setSavedJobIds((prevSavedJobIds) => [...prevSavedJobIds, job.id])
    postSavedJobMutation.mutate(job)
  }

  const jobIsFromApi =
    job.job_publisher_name === 'CareerArc' ||
    job.job_publisher_name === 'Jobtome'

  const handleClick = jobIsFromApi ? saveApiJobAsOutsideJob : saveJobPosting

  if (variant === 'icon') {
    if (isPending) return <Skeleton />

    return (
      <div className="flex flex-row items-center">
        <div className="text-sm italic">
          {includeText && jobPostingIsSaved && t('job_saved')}
        </div>
        <IconButton
          handleClick={handleClick}
          jobPostingIsSaved={jobPostingIsSaved}
        />
      </div>
    )
  } else {
    return (
      <SaveJobButton
        handleClick={handleClick}
        jobPostingIsSaved={jobPostingIsSaved}
      />
    )
  }
}

function SaveJobButton({ jobPostingIsSaved, handleClick, disabled }) {
  const { t } = useTranslation('SaveJobButton')

  if (jobPostingIsSaved) return null

  return (
    <Button
      className="flex flex-row justify-center gap-1 px-4 sm:gap-2 sm:px-6"
      variant="outline"
      onClick={handleClick}
      disabled={disabled}
    >
      <div>{t('save_job')}</div> <BookmarkOutlineIcon className="h-5 w-5" />
    </Button>
  )
}

function IconButton({ jobPostingIsSaved, handleClick, disabled }) {
  if (jobPostingIsSaved)
    return (
      <div className="p-2 text-intoo-blue-medium">
        <BookmarkSolidIcon />
      </div>
    )

  return (
    <Button
      variant="ghost"
      className="rounded-lg px-2"
      onClick={handleClick}
      disabled={disabled}
    >
      <BookmarkOutlineIcon />
    </Button>
  )
}

function Skeleton() {
  return (
    <Button variant="ghost" className="rounded-lg px-2 text-gray-300">
      <BookmarkOutlineIcon />
    </Button>
  )
}

function BookmarkOutlineIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className || 'h-6 w-6'}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
      />
    </svg>
  )
}

function BookmarkSolidIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-6 w-6"
    >
      <path
        fillRule="evenodd"
        d="M6.32 2.577a49.255 49.255 0 0 1 11.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 0 1-1.085.67L12 18.089l-7.165 3.583A.75.75 0 0 1 3.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

const getSavedJobIds = async () => {
  const response = await api.get(
    '/job-search/saved_jobs?job_posting_ids_only=true',
  )
  return response.data
}

const postSavedJob = async ({ job_posting_id }) => {
  const response = await api.post('/job-search/saved_jobs', {
    saved_job: { job_posting_id },
  })
  return response.data
}
