import React from 'react'
import { PrimaryQuizButton } from './Buttons'
import Avatar from './Avatar'
import Clock from './Icons/Clock'
import NotebookIcon from './Icons/NotebookIcon'
import { useTranslation } from '../../hooks/useTranslation'

const StartQuiz = ({
  setStarted,
  avatar,
  quizExists,
  saveQuiz,
  time,
  formattedQuizName,
}) => {
  const buttonText = quizExists ? 'Continue Quiz' : 'Start Quiz'
  const { t } = useTranslation(formattedQuizName)
  const questionsInfo = t('questions')
  const questionsArr = Object.values(questionsInfo)
  const numQuestions = questionsArr.length
  const title = t('start_quiz.title')
  const description = t('start_quiz.description')
  const outcomes = t('start_quiz.outcomes')

  const handleStartQuizClick = () => {
    if (quizExists) {
      setStarted(true)
    } else {
      saveQuiz()
    }
  }

  return (
    <div className="flex flex-col items-center sm:w-9/12">
      <h1
        style={{ fontFamily: 'gotham_boldregular' }}
        className="!mb-0 text-center"
      >
        {title}
      </h1>
      <div className="py-6">
        <div className="relative h-60 w-60">
          <Avatar src={avatar} className={'absolute'} />
          <div className="absolute left-2 top-28 flex h-20 w-40 flex-col justify-around bg-white text-[#08467C]">
            <div
              style={{ fontFamily: 'gotham_boldregular' }}
              className="flex items-center gap-1"
            >
              <Clock />
              {`${time} minutes`}
            </div>
            <div
              style={{ fontFamily: 'gotham_boldregular' }}
              className="flex items-center gap-1"
            >
              <NotebookIcon />
              {`${numQuestions} questions`}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 text-center">
        {description.map((desc, idx) => (
          <div key={`description-p-${idx}`}>{desc}</div>
        ))}
      </div>
      <PrimaryQuizButton
        className="my-8 h-12 rounded-[20px] bg-primary hover:bg-primary-darker-75 sm:h-10"
        onClick={handleStartQuizClick}
      >
        {buttonText}
      </PrimaryQuizButton>
      <div className="rounded-[15px] bg-gradient-to-b from-[#0F80E2] to-[#00C2FF] sm:container">
        <div className="outcomes m-1 flex min-h-28 flex-col rounded-2xl bg-white p-2 sm:gap-2 sm:pt-4">
          <div
            style={{ fontFamily: 'gotham_boldregular' }}
            className="pl-7 text-base"
          >
            {"Unlock Insights: What You'll Learn With This Quiz"}
          </div>
          <ul>
            {outcomes.map((outcome, idx) => (
              <li className="text-sm" key={`outcome-${idx}`}>
                {outcome}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
export default StartQuiz
