import React from 'react'
import Question from './Question'
import Progress from './Progress'
import { Button } from '../../lib/ui/button'
import { useTranslation } from '../../hooks/useTranslation'

const Quiz = ({ quizIndex, saveQuiz, formattedQuizName, ...props }) => {
  // const questionsArr = Object.values(questionsInfo)
  const { t } = useTranslation(formattedQuizName)
  const questionsInfo = t('questions')
  const questionsArr = Object.values(questionsInfo)
  const numQuestions = questionsArr.length

  const handleLeavePage = () => {
    localStorage.removeItem('started')
    localStorage.removeItem('results')
    localStorage.removeItem('idx')
    saveQuiz()
  }

  return (
    <div className="container flex flex-col">
      <div className="flex">
        <Progress current={quizIndex} total={numQuestions} />
        <Button
          className="flex items-start self-start bg-transparent p-0 text-black hover:bg-transparent hover:text-gray-500"
          title="Save and finish later"
          onClick={handleLeavePage}
        >
          <i className="el-icon-remove-circle"></i>
        </Button>
      </div>
      {questionsArr.map((question, idx) => (
        <Question
          key={`question-${idx}`}
          idx={idx}
          questionInfo={question}
          quizIndex={quizIndex}
          numQuestions={numQuestions}
          saveQuiz={saveQuiz}
          {...props}
        />
      ))}
    </div>
  )
}
export default Quiz
