import * as React from 'react'

import { useJobSearch } from './JobSearchProvider'
import { Button } from '../../../lib/ui/button'
import LinkedInIcon from './LinkedInIcon'
import { useTranslation } from '../../../hooks/useTranslation'
import LeaveSiteWarning from '../../shared/LeaveSiteWarning'
import { SpinnerWhite } from '../../shared/Spinner'

export function PrimaryButton({ children, ...props }) {
  const { formDisabled } = useJobSearch()
  const [fakeDisabled, setFakeDisabled] = React.useState(false)
  const fakeQuickSearch = () => {
    setFakeDisabled(true)
    setTimeout(() => {
      setFakeDisabled(false)
    }, 500)
  }

  const showSpinner = formDisabled || fakeDisabled

  return (
    <Button
      size="lg"
      className="w-full"
      disabled={formDisabled}
      type="submit"
      onClick={fakeQuickSearch}
      {...props}
    >
      {children}
      <SpinnerWhite show={showSpinner} className="pl-4" />
    </Button>
  )
}

export function LearnMoreButton({ job }) {
  const { handleSelectJob } = useJobSearch()
  const { t } = useTranslation('LearnMoreButton')

  return (
    <Button onClick={handleSelectJob(job)} className="px-6 sm:px-8">
      {t('learn_more')}
    </Button>
  )
}

export function ApplyNowButton({ job, compact }) {
  const { t } = useTranslation('ApplyNowButton')

  return (
    <LeaveSiteWarning url={job.job_posting_url}>
      <Button
        className="cursor-pointer hover:text-white data-[compact=true]:px-4"
        data-compact={compact}
      >
        {compact ? t('apply') : t('apply_now')}
      </Button>
    </LeaveSiteWarning>
  )
}

export function ShowResultsButton(props) {
  const { formDisabled } = useJobSearch()
  const { t } = useTranslation('ShowResultsButton')

  return (
    <Button type="submit" disabled={formDisabled} {...props}>
      {t('show_results')}
    </Button>
  )
}

export function LinkedInButton({ job, compact }) {
  const companyName = encodeURIComponent(job.company_name)
  const { t } = useTranslation('LinkedInButton')
  const label = compact ? t('connections') : t('see_connections')
  const linkedInURL = `https://www.linkedin.com/search/results/people?company=${companyName}&facetNetwork=%5B%22F%22%2C%22S%22%5D&origin=FACETED_SEARCH`

  return (
    <LeaveSiteWarning url={linkedInURL}>
      <Button
        variant="outline"
        className="flex flex-row justify-center gap-3 px-6 data-[compact=true]:gap-2 data-[compact=true]:px-4 sm:px-8"
        onClick={(e) => e.stopPropagation()}
        data-compact={compact}
      >
        <div>{label}</div> <LinkedInIcon size="20" />
      </Button>
    </LeaveSiteWarning>
  )
}

export function ViewMoreButton() {
  const { handleViewMore, viewMoreButtonDisabled } = useJobSearch()
  const { t } = useTranslation('ViewMoreButton')

  return (
    <Button
      className="w-full"
      onClick={handleViewMore}
      disabled={viewMoreButtonDisabled}
    >
      {t('view_more')}
      <SpinnerWhite show={viewMoreButtonDisabled} className="pl-4" />
    </Button>
  )
}
