import React from 'react'

const AudioCard = ({ audioFile }) => {
  const { headerImageUrl, headerImageAlt, url, title, description } = audioFile

  return (
    <div className="mb-8 overflow-hidden rounded-3xl border border-solid border-[#B9B9B9]">
      <img
        src={headerImageUrl}
        alt={headerImageAlt}
        className="aspect-[22/11] w-full object-cover object-center"
      />
      <div className="p-4">
        <h2
          role="heading"
          tabIndex="0"
          aria-level="2"
          className="!mb-2 !mt-0 truncate text-xl font-bold focus:overflow-visible"
        >
          {title}
        </h2>
        <p className="!my-0 line-clamp-3">{description}</p>
        <div className="mt-6 flex justify-between gap-6">
          <audio className="w-full" controls src={url} />
        </div>
      </div>
    </div>
  )
}

export default AudioCard
