import React, { useState } from 'react'
import AccessibleFormLabel from './AccessibleFormLabel'
import { Box, Input, FormControl, Button, Link } from '@mui/joy'
import ContactUsLink from './ContactUsLink'
import { useData } from './DataProvider'
import { useAlert } from './AlertModal'
import { SpinnerWhite } from '../../shared/Spinner'
import useTranslation from '../../shared/TranslationProvider'

export default function TwoFactorEntry() {
  const { handleFormResponse, getApi, isLoading, setIsLoading } = useData()
  const { setAlertState, setAlertData } = useAlert()

  const { t } = useTranslation()

  const [authenticationCode, setVerificationCode] = useState('')

  const handleAuthenticate = () => {
    setIsLoading(true)
    asyncHandleAuthenticate()
  }

  const asyncHandleAuthenticate = async () => {
    try {
      const response = await getApi().post('/login', {
        user: { otp_attempt: authenticationCode },
      })
      if (!response.data.location && !response.data.react_form_state) {
        setAlertData('Invalid verification code. Please try again.')
        setAlertState('alert')
      }
      setIsLoading(false)
      handleFormResponse(response)
    } catch (error) {
      setAlertData('Invalid verification code. Please try again.')
      setAlertState('alert')
      setIsLoading(false)
    }
  }

  return (
    <Box className="two-factor-entry">
      <Box>
        <h1>{t('TwoFactorEntry.title')}</h1>
      </Box>
      <Box
        className="link-container subtext-container"
        sx={{
          '--textColor': '#000',
        }}
      >
        <p style={{ marginBottom: '1rem' }}>{t('TwoFactorEntry.subtext')}</p>
        <p>{t('TwoFactorEntry.provide_your_code')} </p>
      </Box>
      <Box>
        <FormControl required>
          <AccessibleFormLabel>Verification Code</AccessibleFormLabel>
          <Input
            name="verification_code"
            autoComplete="one-time-code"
            size="lg"
            autoFocus
            value={authenticationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleAuthenticate()
            }}
          />
          <Box className="button-container">
            <Button
              className="submit-button btn"
              size="lg"
              fullWidth
              onClick={handleAuthenticate}
              disabled={isLoading}
            >
              {t('TwoFactorEntry.authenticate')}
              <SpinnerWhite show={isLoading} className="pl-4" />
            </Button>
          </Box>
        </FormControl>
      </Box>
      <ContactUsLink />
    </Box>
  )
}
