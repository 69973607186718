import React from 'react'
import ExampleScripts from './ExampleScripts'
import parse from 'html-react-parser'
import HighlightCommentProvider, {
  useHighlightComment,
} from './HighlightCommentProvider'
import {
  NumberedList,
  TitledBulletList,
  TitledList,
  List,
  BulletedList,
} from './lists'
import CommentBox from './CommentBox'

const Section = ({ section }) => {
  const { title, children } = section
  return (
    <>
      <h2 className="!mt-0 mb-4 text-2xl font-bold text-primary">{title}</h2>
      {children.map((child, index) => (
        <HighlightCommentProvider
          locatorSuffix={`.children[${index}]`}
          key={index}
        >
          <ChildSection child={child} />
        </HighlightCommentProvider>
      ))}
    </>
  )
}

const ChildSection = ({ child }) => {
  const { type } = child
  if (type === 'paragraph') {
    return <Paragraph paragraphObject={child} />
  } else if (type === 'numbered-list') {
    return <NumberedList listObject={child} />
  } else if (type === 'bulleted-list') {
    return <BulletedList listObject={child} />
  } else if (type === 'titled-bullet-list') {
    return <TitledBulletList listObject={child} />
  } else if (type === 'titled-list') {
    return <TitledList listObject={child} />
  } else if (type == 'example-scripts') {
    return <ExampleScripts exampleScriptsObject={child} />
  } else if (type == 'example-form') {
    return <ExampleForm formObject={child} />
  } else if (type == 'list') {
    return <List listObject={child} />
  }
}

const ExampleFormItem = ({ item }) => {
  const { title, required } = item
  const name = title.toLowerCase().replace(' ', '_').replace('*', '')
  const displayTitle = required ? `${title}*` : title
  return (
    <div className="mb-2 flex flex-row items-center justify-between">
      <label htmlFor={name}>{displayTitle}</label>
      <input
        id={name}
        name={name}
        type="text"
        disabled="disabled"
        className="cursor-not-allowed rounded-md bg-gray-100"
        required={required}
      />
    </div>
  )
}

const ExampleForm = ({ formObject }) => {
  const { children } = formObject
  return (
    <div className="mt-8 max-w-xs first:mt-0">
      {children.map((child, index) => (
        <ExampleFormItem key={index} item={child} />
      ))}
    </div>
  )
}

const Paragraph = ({ paragraphObject }) => {
  const { content, plugins, title } = paragraphObject
  const { actionProps, highlightClass } = useHighlightComment()

  const text =
    plugins && plugins.includes('parse-html') ? parse(content) : content
  let pClass = ''
  if (plugins) {
    pClass += plugins.join(' ')
  }
  if (highlightClass) {
    pClass += ' ' + highlightClass
  }
  return (
    <>
      {title && <div className="mt-6 text-xl font-semibold">{title}</div>}
      <div className="mt-4 first:mt-0">
        <p className={pClass} {...actionProps}>
          {text}
        </p>
        <CommentBox />
      </div>
    </>
  )
}

export default Section
