import React, { useEffect, useState } from 'react'
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'

// Animation
import { easeQuadInOut } from 'd3-ease'
import AnimatedProgressProvider from './AnimatedProgressProvider'
import ChangingProgressProvider from './ChangingProgressProvider'
import { TranslationProvider } from '../shared/TranslationProvider'
import translations from './locales.json'

import TrackTile from './TrackTileComponents/TrackTile'

const MyTrack = () => {
  const [progressPercent, setProgressPercent] = useState(38)
  const [goal, setGoal] = useState('Find a New Job')
  const [goalDate, setGoalDate] = useState('February, 2024')
  const [expand, setExpand] = useState(0)
  const [trackerSectionInfo, setTrackerSectionInfo] = useState([])

  const getUserTrackerSections = async () => {
    const res = await fetch('/tracker/get_tracker_sections')
    if (res.ok) {
      const data = await res.json()
      setTrackerSectionInfo(data.trackerSections)
      return res
    }
    throw new Error('res not ok')
  }

  useEffect(() => {
    getUserTrackerSections()
  }, [])

  return (
    <TranslationProvider
      locale={'en'}
      translations={translations}
      scope={'javascript.components.MyTrack'}
    >
      <div
        style={{ justifyContent: 'space-between', padding: '2rem' }}
        className="display-flex track-top"
      >
        <div className="track-info">
          <h2 style={{ margin: '0px' }} className="secondary-color">
            Action Plan
          </h2>
          <div className="col-md-6 action-plan-col">
            <h3>Current Goal:</h3>
            <br />
            <a className="goal-link" href="">
              {goal}
            </a>
          </div>
          <div className="col-md-6 action-plan-col">
            <h3>Target Completion Date:</h3>
            <br />
            <a className="goal-link" href="">
              {goalDate}
            </a>
          </div>
        </div>
        <div className="progress-container">
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={progressPercent}
            duration={1.4}
            easingFunction={easeQuadInOut}
          >
            {(value) => {
              const roundedValue = Math.round(value)
              return (
                <CircularProgressbarWithChildren
                  value={value}
                  text={`${roundedValue}%`}
                  styles={buildStyles({ pathTransition: 'none' })}
                >
                  {/*
                              Width here needs to be (100 - 2 * strokeWidth)% 
                              in order to fit exactly inside the outer progressbar.
                            */}
                  <div style={{ width: '76%' }}>
                    <CircularProgressbar
                      value={value}
                      styles={buildStyles({ pathTransition: 'none' })}
                    />
                  </div>
                </CircularProgressbarWithChildren>
              )
            }}
          </AnimatedProgressProvider>
        </div>
      </div>

      {trackerSectionInfo.map((sectionTile, idx) => {
        return (
          <div
            key={`sectionTile-${idx}`}
            id={
              expand === idx ? `expanded-tile-${idx}` : `collapsed-tile-${idx}`
            }
            className={`section-tile ${
              expand === idx ? 'expanded-tile' : 'collapsed-tile'
            }`}
          >
            <TrackTile
              tileInfo={sectionTile}
              expand={expand}
              setExpand={setExpand}
              expandIndex={idx}
            />
          </div>
        )
      })}
    </TranslationProvider>
  )
}

export default MyTrack
