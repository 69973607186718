import React, { useEffect, useState } from 'react'
import { PrimaryQuizButton, BackQuizButton } from './Buttons'
import LayoutFourOptions from './LayoutFourOptions'
import LayoutTwoOptions from './LayoutTwoOptions'
import Response from './Response'
import { useIsDesktop, useIsMobile } from '../../hooks/useMediaQuery'

const Question = ({
  questionInfo,
  numQuestions,
  quizIndex,
  saveQuiz,
  setQuizIndex,
  setStarted,
  idx,
  trackContent,
  setTrackContent,
  setTasks,
  tasks,
  bucketPath,
}) => {
  const [showResponse, setShowResponse] = useState(false)
  const [responseType, setResponseType] = useState(null)
  const [response, setResponse] = useState()
  const options = questionInfo.options
  const question = questionInfo.question
  const image = questionInfo.image
  const isDesktop = useIsDesktop()
  const isMobile = useIsMobile()
  const [disabledButton, setDisabledButton] = useState(false)

  const updateResponse = (choice) => {
    setResponse(
      choice === 0
        ? questionInfo.feedback.yes_response
        : questionInfo.feedback.no_response,
    )
    setShowResponse(true)
    setResponseType(choice)
  }

  useEffect(() => {
    if (quizIndex === idx) {
      if (trackContent[quizIndex + 1] === 'a') {
        updateResponse(0)
      } else if (trackContent[quizIndex + 1] === 'b') {
        updateResponse(1)
      }
    }
  })

  const handleBackClick = () => {
    if (quizIndex === 0) {
      setStarted(false)
    } else setQuizIndex((prev) => prev - 1)
  }

  const handleNextClick = () => {
    const showResultsPage = quizIndex === numQuestions - 1
    if (showResultsPage) {
      setDisabledButton(true)
      saveQuiz(showResultsPage)
    } else {
      setQuizIndex((prev) => prev + 1)
    }
  }

  const handleOptionClick = (choice) => {
    const show = () => {
      updateResponse(choice)
    }

    trackContent[quizIndex + 1] = choice === 0 ? 'a' : 'b'

    setTrackContent({
      ...trackContent,
    })

    let newTask
    const prevTasks = tasks

    if (choice === 0 && questionInfo.yes_action_results_copy) {
      newTask = questionInfo.yes_action_results_copy
    } else if (choice === 1 && questionInfo.no_action_results_copy) {
      newTask = questionInfo.no_action_results_copy
    } else if (questionInfo.action_results_copy) {
      newTask = questionInfo.action_results_copy
    }

    if (newTask) {
      prevTasks[quizIndex + 1] = newTask
      setTasks({ ...prevTasks })
    }

    show()

    // setTimeout(show, 500)
  }

  const layoutProps = { options, handleOptionClick, quizIndex }

  useEffect(() => {
    if (isMobile && showResponse) {
      window.scrollTo(0, document.body.scrollHeight)
    }
  }, [showResponse])

  return (
    quizIndex === idx && (
      <div className="flex flex-col sm:mr-24 sm:pl-28">
        {/* <div className="py-6 text-2xl sm:pb-10 sm:pt-14">{question}</div> */}
        <h1 className="text-2xl sm:!my-8">{question}</h1>
        <LayoutTwoOptions
          {...layoutProps}
          trackContent={trackContent}
          setShowResponse={setShowResponse}
        >
          {isDesktop && image && (
            <div>
              {image && <img src={bucketPath + image.src} alt={image.alt} />}
            </div>
          )}
        </LayoutTwoOptions>
        <LayoutFourOptions {...layoutProps} />
        <div className="sm:h-40">
          <Response
            response={response}
            responseType={responseType}
            showResponse={showResponse}
            alreadyTransitioned={
              trackContent[quizIndex + 1] === 'a' ||
              trackContent[quizIndex + 1] === 'b'
            }
          />
        </div>

        <div className="mt-8 flex gap-4 self-end sm:gap-6">
          <BackQuizButton onClick={handleBackClick}>Back</BackQuizButton>
          <div className="flex flex-col items-center gap-[10px]">
            <PrimaryQuizButton
              onClick={handleNextClick}
              disabled={disabledButton}
            >
              Continue
            </PrimaryQuizButton>
            {isDesktop && (
              <div>
                {quizIndex + 1} of {numQuestions}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  )
}

export default Question
