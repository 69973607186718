import React, { useEffect, useContext, createContext, useState } from 'react'

const NavContext = createContext()

export const useNav = () => {
  return useContext(NavContext)
}

export const NavProvider = ({ user, dynamicLinks, children }) => {
  const [activeNav, setActiveNav] = useState(null)
  const [activeSubNav, setActiveSubNav] = useState(null)
  const [isExpanded, setIsExpanded] = useState(false)
  const [showPopup, setShowPopup] = useState(false)

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  const navigateTo = (path) => {
    console.log('Navigating to:', path)
  }

  useEffect(() => {
    if (activeNav) {
      console.log('Active Nav:', activeNav)
    }
  }, [activeNav])

  const getLink = (item) => {
    const { dynamicLink, path } = item
    if (dynamicLink) {
      return dynamicLinks[dynamicLink]
    } else {
      return path
    }
  }

  const value = {
    user,
    getLink,
    isExpanded,
    toggleExpanded,
    activeSubNav,
    setActiveSubNav,
    activeNav,
    setActiveNav,
    navigateTo,
    showPopup,
    setShowPopup,
  }

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>
}
