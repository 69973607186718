import React, { useEffect } from 'react'
import {
  LogoBackgroundRight,
  StockImageTop,
  StockImageBottom,
  LatitudeLogo,
} from './images'
import { useData } from '../DataProvider'
import useTheme from '../../../shared/ThemeProvider'
import { RightPanelSolo, RightPanelSoloPlus } from './solo'
import RightPanelFlex from './flex'
import RightPanelPremium from './premium'
import RightPanelSelect from './select'
import UpdatedSitePanel from './UpdatedSitePanel'

const DefaultPanel = () => {
  return (
    <div className="visitor-right">
      <div className="visitor-images-container">
        <StockImageTop />
        <StockImageBottom />
      </div>
      <LogoBackgroundRight />
    </div>
  )
}

const BrandedDefaultPanel = () => {
  const { loginPageWhiteLogoUrl, platformName, loginPageBackgroundImage } =
    useTheme()
  return (
    <div className="visitor-right">
      <div className="visitor-branding-images-container">
        {platformName === 'Latitude' || !loginPageWhiteLogoUrl ? (
          <LatitudeLogo />
        ) : (
          <img
            className="branding-logo"
            src={loginPageWhiteLogoUrl}
            alt={platformName}
          />
        )}
        {platformName === 'Latitude' || !loginPageBackgroundImage ? (
          <StockImageTop />
        ) : platformName != 'Lukkap' ? (
          <img
            className="object-cover object-center"
            src={loginPageBackgroundImage}
            alt={platformName}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

const RegistrationPanel = () => {
  const { programLevel } = useData()
  const { primaryColor, setPrimaryColor, isCustomBranded } = useTheme()

  const selectPanelBg = '#151f3b'

  useEffect(() => {
    if (programLevel === 'SELECT' && !isCustomBranded) {
      setPrimaryColor(selectPanelBg)
    }
  }, [programLevel, setPrimaryColor, isCustomBranded])

  return (
    <div
      className="relative flex flex-grow flex-col items-center overflow-hidden p-2 sm:p-0"
      style={{ backgroundColor: primaryColor }}
    >
      {programLevel === 'SOLO' && <RightPanelSolo />}
      {programLevel === 'SOLO_PLUS' && <RightPanelSoloPlus />}
      {programLevel === 'FLEX' && <RightPanelFlex />}
      {programLevel === 'PREMIUM' && <RightPanelPremium />}
      {programLevel === 'SELECT' && <RightPanelSelect />}
    </div>
  )
}

const RightPanel = () => {
  const { userNotificationEnabled, formState } = useData()
  const { isCustomBranded } = useTheme()

  if ((formState === 'RegistrationForm') & !isCustomBranded) {
    return <RegistrationPanel />
  } else if (userNotificationEnabled && formState === 'LoginForm') {
    return <UpdatedSitePanel />
  } else if (isCustomBranded) {
    return <BrandedDefaultPanel />
  } else {
    return <DefaultPanel />
  }
}

export default RightPanel
