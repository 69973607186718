import React from 'react'
import { Button } from '../../lib/ui/button'
import { cn } from '../../lib/utils'

const Option = ({ children, className, ...props }) => {
  const buttonClass = className === 'selected' ? 'bg-primary text-white' : ''

  return (
    <Button
      className={cn(
        'h-32 whitespace-pre-line rounded-[10px] border border-solid border-[#5A5A5A] bg-white text-black hover:bg-primary hover:text-white focus:bg-primary focus:text-white sm:w-[350px]',
        buttonClass,
      )}
      {...props}
    >
      {children}
    </Button>
  )
}
export default Option
