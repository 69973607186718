export const useScoreCalculator = (content) => {
  const numAs = Object.values(content).filter((val) => val === 'a').length
  const score = Math.round((numAs / Object.keys(content).length) * 100)
  if (numAs <= 8) {
    return [1, numAs]
  } else if (numAs <= 14) {
    return [2, numAs]
  } else {
    return [3, score]
  }

  // return [score, numAs]
}
