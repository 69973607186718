import React, { useEffect, useContext, useState } from 'react'
import { getApi } from '../../util/api'

const DataContext = React.createContext()

export const useData = () => {
  return useContext(DataContext)
}

export default function DataProvider({ activeStep, fetchOnLoad, children }) {
  const interestKeys = [
    'new_job',
    'career_change',
    'contract_job',
    'working_for_self',
    'retirement',
  ]
  const seniorityLevels = [
    ['entry_to_mid', 'Entry to Mid Level'],
    ['manager_to_director', 'Manager to Director Level'],
    ['vp_to_c_level', 'VP to C Level'],
  ]
  const lastRoleTypeValues = [
    ['individual_contributor', 'Employee or Individual Contributor'],
    ['manager', 'Manager'],
  ]
  const seekingEducationValues = ['yes', 'no', 'not_sure']

  const [step, setStep] = useState(parseInt(activeStep))
  const [interest, setInterest] = useState(interestKeys[0])
  const [candidate, setCandidate] = useState({})
  const [desiredJobTitle, setDesiredJobTitle] = useState('')
  const [desiredLocation, setDesiredLocation] = useState('')
  const [desiredIndustry, setDesiredIndustry] = useState('')
  const [aboutYourself, setAboutYourself] = useState('')
  const [linkedInProfile, setLinkedInProfile] = useState('')
  const [twitterProfile, setTwitterProfile] = useState('')
  const [facebookProfile, setFacebookProfile] = useState('')
  const [retirementGoals, setRetirementGoals] = useState('')
  const [desiredOpportunities, setDesiredOpportunities] = useState('')
  const [lastRoleType, setLastRoleType] = useState('')
  const [seniorityLevel, setSeniorityLevel] = useState('')
  const [seekingEducation, setSeekingEducation] = useState('')
  const [notificationPreference, setNotificationPreference] = useState('')
  const [resumeName, setResumeName] = useState('')
  const [resumeFileAcceptList, setResumeFileAcceptList] = useState('')
  const [serverTranslations, setServerTranslations] = useState({})

  const interestIsRetirement = interest === 'retirement'
  const interestIsWorkingForSelf = interest === 'working_for_self'
  const interestIsJobSeeking =
    interest !== 'retirement' && interest !== 'working_for_self'

  const setCurrentStep = (step) => {
    getApi().patch('/onboarding/', {
      social_media_profile: {
        linked_in_profile_url: linkedInProfile,
        twitter_profile_url: twitterProfile,
        facebook_profile_url: facebookProfile,
      },
      candidate: {
        seniority_level: seniorityLevel,
        candidate_profile_attributes: {
          job_title: desiredJobTitle,
          industry: desiredIndustry,
          location: desiredLocation,
          interest: interest,
          last_role_type: lastRoleType,
          about_yourself: aboutYourself,
          retirement_goals: retirementGoals,
          desired_opportunities: desiredOpportunities,
          seeking_education: seekingEducation,
          notification_preference: notificationPreference,
        },
      },
    })
    if (step > 5) {
      window.location.href = '/'
    } else {
      setStep(step)
      window.scrollTo(0, 0)
    }
  }

  const loadOnboardingInfo = async () => {
    const {
      data: {
        resumeFileAcceptList,
        candidate,
        candidateProfile,
        socialMediaProfile,
        translatedStrings,
      },
    } = await getApi().get('/onboarding/1')
    setCandidate(candidate)
    setResumeFileAcceptList(resumeFileAcceptList)
    if (candidateProfile) {
      setDesiredJobTitle(candidateProfile.desiredJobTitle)
      setDesiredIndustry(candidateProfile.desiredIndustry)
      setDesiredLocation(candidateProfile.desiredLocation)
      setSeniorityLevel(candidateProfile.seniorityLevel)
      setResumeName(candidateProfile.resumeName)
      setInterest(candidateProfile.interest)
      setDesiredOpportunities(candidateProfile.desiredOpportunities)
      setLastRoleType(candidateProfile.lastRoleType)
      setAboutYourself(candidateProfile.aboutYourself)
      setRetirementGoals(candidateProfile.retirementGoals)
      setLastRoleType(candidateProfile.lastRoleType)
      setSeekingEducation(candidateProfile.seekingEducation)
      setNotificationPreference(candidateProfile.notificationPreference)
    }
    if (socialMediaProfile) {
      setLinkedInProfile(socialMediaProfile.linkedInProfile || '')
      setTwitterProfile(socialMediaProfile.twitterProfile || '')
      setFacebookProfile(socialMediaProfile.facebookProfile || '')
    }
    if (translatedStrings) {
      setServerTranslations(translatedStrings)
    }
  }

  useEffect(() => {
    fetchOnLoad && loadOnboardingInfo()
  }, [fetchOnLoad])

  const data = {
    step,
    loadOnboardingInfo,
    candidate,
    interest,
    interestKeys,
    desiredJobTitle,
    desiredLocation,
    desiredIndustry,
    aboutYourself,
    retirementGoals,
    linkedInProfile,
    twitterProfile,
    facebookProfile,
    resumeName,
    resumeFileAcceptList,
    desiredOpportunities,
    lastRoleType,
    lastRoleTypeValues,
    seniorityLevel,
    seniorityLevels,
    seekingEducation,
    seekingEducationValues,
    notificationPreference,
    setInterest,
    setCurrentStep,
    setDesiredJobTitle,
    setDesiredLocation,
    setDesiredIndustry,
    setAboutYourself,
    setRetirementGoals,
    setLinkedInProfile,
    setTwitterProfile,
    setFacebookProfile,
    setResumeName,
    setDesiredOpportunities,
    setLastRoleType,
    setSeniorityLevel,
    setSeekingEducation,
    setNotificationPreference,
    getApi,
    interestIsRetirement,
    interestIsWorkingForSelf,
    interestIsJobSeeking,
    serverTranslations,
  }

  return <DataContext.Provider value={data}>{children}</DataContext.Provider>
}
