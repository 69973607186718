import React from 'react'
import Section from './Section'
import { LeftPanel, NavHeader } from './controls'
import { Button } from '../../lib/ui/button'
import { BackArrow } from './icons'
import { ListItems } from './lists'
import ArticleProvider, { useArticle } from './ArticleProvider'
import HighlightCommentProvider from './HighlightCommentProvider'

const ArticleInner = () => {
  const {
    jsonContent: { highlights, heroImage },
  } = useArticle()

  return (
    <div className="article-redesign flex w-full flex-col">
      <NavHeader />
      <div className="flex w-full flex-row">
        <LeftPanel />
        <div className="mt-4 flex flex-grow flex-row justify-around font-[Roboto] text-base antialiased md:pl-4">
          <div className="mx-4 max-w-5xl 2xl:m-0">
            {highlights && <Highlights />}
            {heroImage && <Hero />}
            <Sections />
          </div>
        </div>
      </div>
    </div>
  )
}

const Article = ({ ...props }) => {
  return (
    <ArticleProvider {...props}>
      <HighlightCommentProvider>
        <ArticleInner />
      </HighlightCommentProvider>
    </ArticleProvider>
  )
}

const Highlights = () => {
  const {
    jsonContent: { highlights },
  } = useArticle()
  return (
    <div className="mb-10 mt-4 rounded-2xl border-2 border-solid border-[#00C2FF] p-8">
      <div>
        <h3 className="!mt-0 mb-4 !text-2xl font-bold text-black">
          Highlights
        </h3>
        <ol className="list-inside list-decimal space-y-4 text-black">
          <HighlightCommentProvider locatorSuffix=".highlights">
            <ListItems items={highlights} locatorSuffix=".highlights" />
          </HighlightCommentProvider>
        </ol>
      </div>
    </div>
  )
}

const Hero = () => {
  const {
    heroAwsUrl,
    jsonContent: {
      heroImage: { title },
    },
  } = useArticle()
  return (
    <div className="mb-10">
      <img
        className="aspect-[11/5] w-full rounded-2xl object-cover object-center shadow-md shadow-slate-500/40"
        alt={title}
        src={heroAwsUrl}
      />
    </div>
  )
}

const Sections = () => {
  const {
    jsonContent: { sections },
    sectionRef,
  } = useArticle()

  const lastIndex = sections.length - 1

  return (
    <div ref={sectionRef}>
      {sections.map((section, index) => (
        <div key={index} className="scroll-mt-[35px] sm:scroll-mt-[200px]">
          <HighlightCommentProvider locatorSuffix={`.sections[${index}]`}>
            <Section section={section} />
          </HighlightCommentProvider>
          {index !== lastIndex && <SectionDivider />}
        </div>
      ))}
      <CompletionNotice />
    </div>
  )
}

const SectionDivider = () => {
  return (
    <div className="my-8 w-full">
      <hr className="w-full border-[1.5px] border-solid border-primary" />
    </div>
  )
}

const CompletionNotice = () => {
  const {
    hasNotifiedViewed,
    completionAnimationUrl,
    navigateToResourceCenter,
    jsonContent: { title, guideComplete },
  } = useArticle()

  const contentTitle = guideComplete?.title || 'Congratulations!'
  const message =
    guideComplete?.content || `${title} has been marked as complete.`
  const className = hasNotifiedViewed ? 'mt-6' : 'hidden'
  return (
    <div className={className}>
      <div className="mb-8 flex w-full flex-row items-center">
        <img
          className="w-20"
          src={completionAnimationUrl}
          alt="Article complete"
        />
        <div className="flex flex-col">
          <div className="text-2xl font-bold text-[#27772B]">
            {contentTitle}
          </div>
          <div>{message}</div>
        </div>
      </div>
      <div className="mb-4">
        <Button
          variant="default"
          className="h-12 rounded-[1.25rem] py-3 font-medium sm:h-10 sm:py-2"
          onClick={navigateToResourceCenter}
        >
          Resource Center
          <BackArrow className="ml-2 stroke-white" />
        </Button>
      </div>
    </div>
  )
}

export default Article
