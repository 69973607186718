import React from 'react'
import MyTrack from '../MyTrack/MyTrack'
const Tracker = () => {
  return (
    <div className="platform-home-container">
      <div className="col-md-12">
        <div
          className="home-div my-track display-flex"
          style={{ flexDirection: 'column' }}
        >
          <MyTrack />
        </div>
      </div>
    </div>
  )
}
export default Tracker
