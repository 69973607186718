import React, { useEffect, useState } from 'react'
import { No, Yes } from './Icons/YesNo'
import Option from './Option'

const LayoutTwoOptions = ({
  options,
  handleOptionClick,
  quizIndex,
  children,
  trackContent,
}) => {
  // const focusIndex = trackContent[quizIndex + 1] === 'a' ? 0 : 1
  const [focus, setFocus] = useState()
  const [hover, setHover] = useState()
  useEffect(() => {
    if (trackContent[quizIndex + 1] === 'a') {
      setFocus(0)
    } else if (trackContent[quizIndex + 1] === 'b') {
      setFocus(1)
    }
  }, [focus, trackContent, quizIndex])

  return (
    options.length == 2 && (
      <div className="z-10 justify-between sm:flex">
        <div className="question-options flex flex-col gap-6 sm:gap-8">
          {options.map((option, index) => (
            <Option
              key={`response-${index}`}
              id={`btn-${quizIndex + 1}-${index}`}
              className={focus === index ? 'selected' : ''}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(null)}
              onClick={() => {
                handleOptionClick(index)
                setFocus(index)
              }}
            >
              <div className="flex flex-col items-center gap-2">
                {index === 0 ? (
                  <Yes white={focus === 0 || hover === 0} />
                ) : (
                  <No white={focus === 1 || hover === 1} />
                )}
                <div className="break-words">{option}</div>
              </div>
            </Option>
          ))}
        </div>
        {children}
      </div>
    )
  )
}
export default LayoutTwoOptions
