import React, { useEffect, useState } from 'react'
import Progress from './Progress'
import Avatar from './Avatar'
import { useIsDesktop } from '../../hooks/useMediaQuery'
import { DownArrow, RightArrow } from './Icons/Arrows'
import Task from './Task'
import { PrimaryQuizButton, BackQuizButton } from './Buttons'
import { useTranslation } from '../../hooks/useTranslation'
import { useScoreCalculator } from '../JobSearchQuiz/useScoreCalculator'

const Results = ({
  avatar,
  tasks,
  setShowResults,
  formattedQuizName,
  trackContent,
}) => {
  const resultsTasks = Object.values(tasks)
  const isDesktop = useIsDesktop()
  const [disabledButton, setDisabledButton] = useState(false)
  const { t } = useTranslation(formattedQuizName)
  const [header, setHeader] = useState('')
  const [copy, setCopy] = useState('')
  // const scoreCalculator = useScoreCalculator()
  const [scoreSection, variable] = useScoreCalculator(trackContent)

  const handleNextClick = () => {
    localStorage.removeItem('started')
    localStorage.removeItem('results')
    localStorage.removeItem('idx')
    setDisabledButton(true)
    window.location.pathname = '/'
  }

  useEffect(() => {
    setHeader(t(`results.${scoreSection}.header`))
    setCopy(
      scoreSection === 2
        ? t(`results.${scoreSection}.copy`, { numAs: variable })
        : t(`results.${scoreSection}.copy`, { score: variable }),
    )
  }, [resultsTasks])

  return (
    <div className="container mt-6 flex flex-col">
      <Progress completed />
      <div className="flex pb-6 sm:justify-around">
        <div className="mt-1 sm:w-9/12">
          <h1 className="!text-[23px]">{header}</h1>
          <p className="mt-4">{copy}</p>
        </div>
        {isDesktop && <Avatar src={avatar} />}
      </div>
      <div className="divide-y divide-solid divide-[#B9B9B9] rounded-[10px] border-[1.5px] border-solid border-[#08467C]">
        <div
          style={{ fontFamily: 'gotham_boldregular' }}
          className="flex flex-col items-center py-4 sm:flex-row sm:pl-16"
        >
          <div className="sm:w-2/5">Remaining areas of preparation</div>
          {!isDesktop && <DownArrow className="py-2" />}
          {isDesktop && <RightArrow className="sm:w-1/5" />}
          <div className="flex flex-col items-center sm:w-2/5">
            <div>Tasks added to My Track</div>
          </div>
        </div>
        {resultsTasks.map((task, idx) => {
          const { task: taskText, links } = task
          return (
            <Task
              key={`task-${idx}`}
              className={`flex flex-col items-center py-4 sm:flex-row sm:pl-16`}
              task={taskText}
              links={links}
              showArrow={isDesktop}
            />
          )
        })}
      </div>
      <div className="mt-8 flex gap-4 self-end sm:gap-6">
        <BackQuizButton onClick={() => setShowResults(false)}>
          Back
        </BackQuizButton>
        <div className="flex flex-col items-center gap-2">
          <PrimaryQuizButton
            onClick={handleNextClick}
            disabled={disabledButton}
          >
            My Track
          </PrimaryQuizButton>
        </div>
      </div>
    </div>
  )
}

export default Results
